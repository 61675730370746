import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import useDarkMode from '../../../../hooks/useDarkMode';
import Spinner from '../../../../components/bootstrap/Spinner';
import TableOnboarding from './TableOnboarding';
import OnboardingDataModule from '../../../../modules/bakti/job/OnboardingDataModule';
import { getRequester } from '../../../../helpers/helpers';
import LoadingModal from '../../../../components/custom/LoadingModal';

const handleUpdate = (id, values) => {
	const newResponse = new Promise((resolve, reject) => {
		try {
			OnboardingDataModule.updateByID(id, values)
				.then((res) => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: res?.message ?? 'Data has been updated successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
	return newResponse;
};

const FormOnboarding = () => {
	const { darkModeStatus } = useDarkMode();
	const { username } = getRequester();

	const [loading, setLoading] = useState(false);
	const [reload, setReload] = useState(true);
	const [progress, setProgress] = useState(false);

	const initValues = {
		interview_form_id: '',
		interview_form_name: '',
		interview_form_obj: null,
		formal_photo_id: '',
		formal_photo_name: '',
		formal_photo_obj: null,
		kartu_keluarga_id: '',
		kartu_keluarga_name: '',
		kartu_keluarga_obj: null,
		bpjs_kesehatan_id: '',
		bpjs_kesehatan_name: '',
		bpjs_kesehatan_obj: null,
		bpjs_ketenagakerjaan_id: '',
		bpjs_ketenagakerjaan_name: '',
		bpjs_ketenagakerjaan_obj: null,
		bank_account_id: '',
		bank_account_name: '',
		bank_account_obj: null,
		bank_account_number: '',
		bank_account_branch: '',
		npwp_id: '',
		npwp_name: '',
		npwp_obj: null,
		ktp_number: '',
		kjp_number: '',
		registered_tax: '',
		actual_tax: '',
	};

	const [init, setInit] = useState(initValues);

	const updateSubmit = (id, values) => {
		setLoading(true);

		handleUpdate(id, values)
			.then(() => {
				setReload(true);
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		const fetch = async () => {
			try {
				await fetchData();
			} catch (err) {
				//
			}
		};

		if (reload) fetch();

		return () => {
			//
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reload]);

	const fetchData = async () => {
		setLoading(true);
		setProgress(false);

		const query = {};

		return OnboardingDataModule.readByID(username, query)
			.then((response) => {
				setInit((e) => ({ ...e, ...response }));
			})
			.catch(() => {
				setInit(initValues);
			})
			.finally(() => {
				setLoading(false);
				setReload(false);
				setProgress(true);
			});
	};

	if (!progress) {
		return (
			<Card stretch>
				<CardHeader
					size='sm'
					borderSize={1}
					style={{ backgroundColor: darkModeStatus ? '#4d69fa33' : '#edf0ff' }}>
					<CardLabel icon='Contacts' iconColor='info'>
						<CardTitle>Onboarding Data</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody isScrollable>
					<div style={{ display: 'grid', placeItems: 'center' }}>
						<Spinner color='info' size='8rem' />
					</div>
				</CardBody>
			</Card>
		);
	}

	return (
		<>
			<TableOnboarding initialValues={init} loading={loading} onSubmit={updateSubmit} />

			<LoadingModal loading={loading} setLoading={setLoading} />
		</>
	);
};

FormOnboarding.propTypes = {};
FormOnboarding.defaultProps = {};

export default FormOnboarding;
