import axios from 'axios';
import authHeader from '../auth-header.service';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const read = async (query) => {
	return axios.get(`${API_URL_DEFAULT}nationalities`, {
		headers: await authHeader(),
		params: query,
	});
};

export default { read };
