import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import DarkDataTable from '../../../../components/DarkDataTable';
import useDarkMode from '../../../../hooks/useDarkMode';
import Button from '../../../../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import ProfileEmpModule from '../../../../modules/bakti/master/ProfileEmpModule';
import LoadingModal from '../../../../components/custom/LoadingModal';

const handleUpdate = (id, values) => {
	const newResponse = new Promise((resolve, reject) => {
		try {
			ProfileEmpModule.updateEducationInformal(id, values)
				.then((res) => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: res?.message ?? 'Data has been updated successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
	return newResponse;
};

const handleDelete = (id, values) => {
	// eslint-disable-next-line no-async-promise-executor
	const newResponse = new Promise(async (resolve, reject) => {
		try {
			Swal.fire({
				heightAuto: false,
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!',
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					ProfileEmpModule.updateEducationInformal(id, values)
						.then((res) => {
							Swal.fire({
								heightAuto: false,
								title: 'Information!',
								text: res?.message ?? 'Data has been deleted successfully',
								icon: 'success',
							});
							resolve({ error: false, message: 'successfully' });
						})
						.catch((err) => {
							Swal.fire({
								heightAuto: false,
								title: 'Warning!',
								text: err,
								icon: 'error',
							});
							reject(new Error(err));
						});
				}
			});
		} catch (e) {
			reject(new Error({ error: true }));
		}
	});
	return newResponse;
};

const FormCreate = ({
	initialValues,
	isOpen,
	setIsOpen,
	title,
	size,
	state,
	isUpdate,
	onSubmit,
}) => {
	const { darkModeStatus } = useDarkMode();

	const formik = useFormik({
		initialValues: { ...initialValues },
		validationSchema: Yup.object({
			title: Yup.string().required('Required'),
			field_study: Yup.string().required('Required'),
			institution: Yup.string().required('Required'),
			year: Yup.string().required('Required'),
			fund_service: Yup.string().required('Required'),
			certificate_link: Yup.string().required('Required'),
		}),
		onReset: () => {
			setIsOpen(false);
		},
		onSubmit: (values, { setSubmitting, setErrors }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: 'Please check your entries !',
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes',
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						const new_data = {
							type: isUpdate ? 'update' : 'add',
							informal: JSON.stringify({
								title: values.title,
								field_study: values.field_study,
								institution: values.institution,
								year: values.year,
								fund_service: values.fund_service,
								certificate_link: values.certificate_link,
							}),
							_id: values?._id,
						};

						onSubmit(new_data, state, setIsOpen);
					}
				});
			} catch (error) {
				setErrors({ submit: error.message });
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			} finally {
				setSubmitting(false);
			}
		},
	});

	const renderComponent = (
		<div className='row'>
			<div className='col-md-12 my-2'>
				<FormGroup id='title' label='Title' isFloating>
					<Input
						data-testid='inputTitle'
						type='text'
						autoComplete='off'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.title}
						isValid={formik.isValid}
						isTouched={formik.touched.title}
						invalidFeedback={formik.errors.title}
					/>
				</FormGroup>
			</div>
			<div className='col-md-12 my-2'>
				<FormGroup id='field_study' label='Field Study' isFloating>
					<Input
						data-testid='inputFieldStudy'
						type='text'
						autoComplete='off'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.field_study}
						isValid={formik.isValid}
						isTouched={formik.touched.field_study}
						invalidFeedback={formik.errors.field_study}
					/>
				</FormGroup>
			</div>
			<div className='col-md-12 my-2'>
				<FormGroup id='institution' label='Institution' isFloating>
					<Input
						data-testid='inputInstitution'
						type='text'
						autoComplete='off'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.institution}
						isValid={formik.isValid}
						isTouched={formik.touched.institution}
						invalidFeedback={formik.errors.institution}
					/>
				</FormGroup>
			</div>
			<div className='col-md-12 my-2'>
				<FormGroup id='year' label='Year' isFloating>
					<Input
						data-testid='inputYear'
						type='number'
						autoComplete='off'
						min={1970}
						max={2100}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.year}
						isValid={formik.isValid}
						isTouched={formik.touched.year}
						invalidFeedback={formik.errors.year}
					/>
				</FormGroup>
			</div>
			<div className='col-md-12 my-2'>
				<FormGroup id='fund_service' label='Fund Service' isFloating>
					<Input
						data-testid='inputFundService'
						type='text'
						autoComplete='off'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.fund_service}
						isValid={formik.isValid}
						isTouched={formik.touched.fund_service}
						invalidFeedback={formik.errors.fund_service}
					/>
				</FormGroup>
			</div>
			<div className='col-md-12 my-2'>
				<FormGroup id='certificate_link' label='Certificate Link' isFloating>
					<Input
						data-testid='inputCertificateLink'
						type='text'
						autoComplete='off'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.certificate_link}
						isValid={formik.isValid}
						isTouched={formik.touched.certificate_link}
						invalidFeedback={formik.errors.certificate_link}
					/>
				</FormGroup>
			</div>
		</div>
	);

	return (
		<Modal
			titleId='form-create'
			isOpen={isOpen}
			setIsOpen={formik.handleReset}
			size={size}
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={formik.handleReset}>
				<ModalTitle id='form-create'>{title}</ModalTitle>
			</ModalHeader>
			<div tag='form' noValidate onSubmit={formik.handleSubmit}>
				<ModalBody>{renderComponent}</ModalBody>
				<ModalFooter>
					<Button
						data-testid='buttonClose'
						color='info'
						type='reset'
						onClick={formik.handleReset}
						isLink
						className='mx-1'>
						Close
					</Button>

					<Button
						data-testid='buttonSave'
						icon='Save'
						color='info'
						type='submit'
						onClick={formik.handleSubmit}
						isLight={darkModeStatus}
						className='mx-1'>
						Save
					</Button>
				</ModalFooter>
			</div>
		</Modal>
	);
};

FormCreate.propTypes = {
	initialValues: PropTypes.oneOfType([PropTypes.object]),
	state: PropTypes.oneOfType([PropTypes.object]),
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	title: PropTypes.string,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
	isUpdate: PropTypes.bool,
	onSubmit: PropTypes.func,
};
FormCreate.defaultProps = {
	initialValues: null,
	state: null,
	isOpen: false,
	setIsOpen: () => false,
	title: 'Modal',
	size: null,
	isUpdate: false,
	onSubmit: () => {},
};

const ButtonCustom = ({ data, state, onRemove, onSubmit }) => {
	const { darkModeStatus } = useDarkMode();

	const [openEdit, setOpenEdit] = useState(false);

	return (
		<div>
			<Button
				data-testid='buttonEdit'
				icon='Edit'
				color='info'
				type='button'
				onClick={() => setOpenEdit(true)}
				isLight={darkModeStatus}
				className='mx-1'
			/>

			<Button
				data-testid='buttonDelete'
				icon='Delete'
				color='danger'
				type='button'
				onClick={() => onRemove({ type: 'remove', _id: data?.informal?._id }, state)}
				isLight={darkModeStatus}
				className='mx-1'
			/>

			<FormCreate
				initialValues={{ ...data?.informal }}
				isOpen={openEdit}
				setIsOpen={setOpenEdit}
				title='Edit Informal Education'
				isUpdate
				onSubmit={onSubmit}
			/>
		</div>
	);
};

ButtonCustom.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object]),
	state: PropTypes.oneOfType([PropTypes.object]),
	onRemove: PropTypes.func,
	onSubmit: PropTypes.func,
};
ButtonCustom.defaultProps = {
	data: null,
	state: {
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: false,
		filter: {},
	},
	onRemove: () => {},
	onSubmit: () => {},
};

const TableCustom = ({ state, onPageChange, onRemove, onSubmit }) => {
	const { darkModeStatus } = useDarkMode();

	const handlePageChange = (page) => {
		onPageChange({ ...state, curPage: page, reload: true });
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		const all = newPerPage === state.totalRows;
		onPageChange({ ...state, perPage: newPerPage, curPage: page, showAll: all, reload: true });
	};

	const paginationComponentOptions = {
		selectAllRowsItem: true,
		selectAllRowsItemText: 'ALL',
	};

	const columns = useMemo(
		() => [
			{
				name: ['No'],
				selector: (row, indexRow) =>
					state.perPage * state.curPage + indexRow - state.perPage + 1,
				sortable: false,
				width: '60px',
			},
			{
				name: ['Title'],
				selector: (row) => row?.informal?.title,
				sortable: false,
			},
			{
				name: ['Field Study'],
				selector: (row) => row?.informal?.field_study,
				sortable: false,
			},
			{
				name: ['Institution'],
				selector: (row) => row?.informal?.institution,
				sortable: false,
			},
			{
				name: ['Year'],
				selector: (row) => row?.informal?.year,
				sortable: false,
				width: '100px',
			},
			{
				name: ['Fun Source'],
				selector: (row) => row?.informal?.fund_service,
				sortable: false,
				width: '140px',
			},
			{
				name: ['Certificate (Link)'],
				selector: (row) => row?.informal?.certificate_link,
				sortable: false,
				width: '140px',
			},
			{
				name: ['Action'],
				width: '140px',
				// eslint-disable-next-line react/no-unstable-nested-components
				cell: (row) => {
					return (
						<ButtonCustom
							data={row}
							state={state}
							onRemove={onRemove}
							onSubmit={onSubmit}
						/>
					);
				},
			},
		],
		[onRemove, onSubmit, state],
	);
	return (
		<DarkDataTable
			columns={columns}
			data={state.data}
			pagination
			paginationServer
			progressPending={state.loading}
			paginationTotalRows={state.totalRows}
			paginationResetDefaultPage={state.isReset}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			paginationComponentOptions={paginationComponentOptions}
			theme={darkModeStatus ? 'custom_dark' : 'light'}
		/>
	);
};

TableCustom.propTypes = {
	state: PropTypes.oneOfType([PropTypes.object]),
	onPageChange: PropTypes.func,
	onRemove: PropTypes.func,
	onSubmit: PropTypes.func,
};
TableCustom.defaultProps = {
	state: {
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: false,
		filter: {},
	},
	onPageChange: () => {},
	onRemove: () => {},
	onSubmit: () => {},
};

const TableInformal = ({ id }) => {
	const { darkModeStatus } = useDarkMode();

	const [openCreate, setOpenCreate] = useState(false);
	const [loading, setLoading] = useState(false);

	const [state, setState] = useState({
		title: 'Informal',
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: true,
		filter: {},
	});

	const initialValues = {
		_id: id,
		title: '',
		field_study: '',
		institution: '',
		year: '',
		fund_service: '',
		certificate_link: '',
	};

	const updateSubmit = (values, _state, showForm) => {
		setLoading(true);

		handleUpdate(id, values)
			.then(() => {
				showForm(false);
				setState((e) => ({ ...e, ..._state, reload: true }));
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const deleteSubmit = (values, _state) => {
		handleDelete(id, values)
			.then(() => {
				setState((e) => ({ ...e, ..._state, reload: true }));
			})
			.catch(() => {})
			.finally(() => {});
	};

	useEffect(() => {
		const fetch = async () => {
			try {
				await fetchData(state.curPage, state.perPage, state.showAll);
			} catch (err) {
				//
			}
		};

		if (state.reload && id) fetch();

		return () => {
			//
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.reload, id]);

	const fetchData = async (curPage, perPage, showAll = false) => {
		setState((e) => ({ ...e, loading: true }));

		const query = {
			page: curPage,
			sizePerPage: perPage,
			showAll,
		};

		return ProfileEmpModule.readEducationInformal(id, query)
			.then((res) => {
				setState((e) => ({
					...e,
					data: res.foundData,
					totalRows: res.countData,
					curPage: res.currentPage,
					perPage,
				}));
			})
			.catch(() => {
				setState((e) => ({
					...e,
					data: [],
					totalRows: 0,
					curPage: 1,
					perPage: 0,
					showAll: false,
				}));
			})
			.finally(() => {
				setState((e) => ({ ...e, loading: false, reload: false }));
			});
	};

	return (
		<div>
			<Card shadow='sm'>
				<CardHeader size='sm' borderSize={1}>
					<CardLabel>
						<CardTitle>{state.title}</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody>
					<div className='d-flex justify-content-end my-1'>
						<div>
							<Button
								data-testid='buttonAddNew'
								icon='Add'
								color='info'
								type='button'
								isLight={darkModeStatus}
								onClick={() => setOpenCreate(true)}>
								Add New
							</Button>
						</div>
					</div>
					<div className='my-1'>
						<TableCustom
							state={state}
							onSubmit={updateSubmit}
							onRemove={deleteSubmit}
							onPageChange={setState}
						/>
					</div>
				</CardBody>
			</Card>

			<FormCreate
				initialValues={initialValues}
				isOpen={openCreate}
				setIsOpen={setOpenCreate}
				title='Add Informal Education'
				state={state}
				onSubmit={updateSubmit}
			/>

			<LoadingModal loading={loading} setLoading={setLoading} />
		</div>
	);
};

TableInformal.propTypes = {
	id: PropTypes.string,
};
TableInformal.defaultProps = {
	id: null,
};

export default TableInformal;
