import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import PageLayoutHeader from '../../../pages/common/Headers/PageLayoutHeader';
import Page from '../../../layout/Page/Page';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import FormBiodata from './form-biodata/FormBiodata';
import FormEducation from './form-education/FormEducation';
import FormFileDocument from './form-file-document/FormFileDocument';
import FormOnboarding from './form-onboarding/FormOnBoarding';
import FormSkillLanguage from './form-skill-language/FormSkillLanguage';
import FormWorkExperience from './form-work-experience/FormWorkExperience';
import ProfileEmpModule from '../../../modules/bakti/master/ProfileEmpModule';
import OnboardingDataModule from '../../../modules/bakti/job/OnboardingDataModule';
import { getRequester } from '../../../helpers/helpers';

const Profile = () => {
	const { username } = getRequester();

	const TABS = {
		BIODATA: 'Biodata',
		EDUCATION: 'Education',
		SKILL_LANGUAGE: 'Skill & Language',
		WORK_EXPERIENCE: 'Work Experience',
		FILE_DOCUMENT: 'Files / Document',
		ON_BOARDING: 'Onboarding Data',
	};

	const [state] = useState({ title: 'Profile' });
	const [activeTab, setActiveTab] = useState(TABS.BIODATA);

	const [idProfile, setIdProfile] = useState(null);
	const [loading, setLoading] = useState(false);
	const [reload, setReload] = useState(true);

	const [enableOnboarding, setEnableOnboarding] = useState(false);

	useEffect(() => {
		const fetch = async () => {
			try {
				await fetchData();
				await fetchDataOnboarding();
			} catch (err) {
				//
			}
		};

		if (reload && activeTab === TABS.BIODATA) fetch();

		return () => {
			//
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reload]);

	const fetchData = async () => {
		setLoading(true);

		return ProfileEmpModule.readByUserID(username)
			.then((response) => {
				setIdProfile(response?._id);
			})
			.catch(() => {
				setIdProfile(null);
			})
			.finally(() => {
				setLoading(false);
				setReload(false);
			});
	};

	const fetchDataOnboarding = async () => {
		return OnboardingDataModule.readApplied(username)
			.then(() => {
				setEnableOnboarding(true);
			})
			.catch(() => {
				setEnableOnboarding(false);
			})
			.finally(() => {});
	};

	return (
		<PageWrapper title={state.title}>
			<PageLayoutHeader />
			<Page container='fluid'>
				<div className='row h-100'>
					{/*  */}
					<div className='col-xxl-2 col-xl-3 col-lg-4'>
						<Card stretch>
							<CardHeader>
								<CardLabel icon='Person' iconColor='info'>
									<CardTitle>Profile</CardTitle>
									<CardSubTitle>Personal Information</CardSubTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<div className='col-12'>
										<Button
											icon='Person'
											color='info'
											className='w-100 p-3'
											isLight={TABS.BIODATA !== activeTab}
											onClick={() => {
												setActiveTab(TABS.BIODATA);
												setReload(true);
											}}>
											{TABS.BIODATA}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='School'
											color='info'
											className='w-100 p-3'
											isLight={TABS.EDUCATION !== activeTab}
											onClick={() => setActiveTab(TABS.EDUCATION)}>
											{TABS.EDUCATION}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Contacts'
											color='info'
											className='w-100 p-3'
											isLight={TABS.SKILL_LANGUAGE !== activeTab}
											onClick={() => setActiveTab(TABS.SKILL_LANGUAGE)}>
											{TABS.SKILL_LANGUAGE}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='ContentPaste'
											color='info'
											className='w-100 p-3'
											isLight={TABS.WORK_EXPERIENCE !== activeTab}
											onClick={() => setActiveTab(TABS.WORK_EXPERIENCE)}>
											{TABS.WORK_EXPERIENCE}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='FilePresent'
											color='info'
											className='w-100 p-3'
											isLight={TABS.FILE_DOCUMENT !== activeTab}
											onClick={() => setActiveTab(TABS.FILE_DOCUMENT)}>
											{TABS.FILE_DOCUMENT}
										</Button>
									</div>
									<div className='col-12 border-bottom' />
									<div className='col-12'>
										<Button
											icon='Contacts'
											color='info'
											className='w-100 p-3'
											isDisable={!enableOnboarding}
											isLight={TABS.ON_BOARDING !== activeTab}
											onClick={() => setActiveTab(TABS.ON_BOARDING)}>
											{TABS.ON_BOARDING}
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>

					{/*  */}
					<div className='col-xxl-10 col-xl-9 col-lg-8'>
						{TABS.BIODATA === activeTab && (
							<FormBiodata id={idProfile} isLoading={loading} />
						)}
						{TABS.EDUCATION === activeTab && (
							<FormEducation id={idProfile} isLoading={loading} />
						)}
						{TABS.SKILL_LANGUAGE === activeTab && (
							<FormSkillLanguage id={idProfile} isLoading={loading} />
						)}
						{TABS.WORK_EXPERIENCE === activeTab && (
							<FormWorkExperience id={idProfile} isLoading={loading} />
						)}
						{TABS.FILE_DOCUMENT === activeTab && (
							<FormFileDocument id={idProfile} isLoading={loading} />
						)}
						{TABS.ON_BOARDING === activeTab && <FormOnboarding />}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

Profile.propTypes = {};
Profile.defaultProps = {};

export default Profile;
