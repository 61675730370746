import axios from 'axios';
// import authHeader from '../bakti/auth-header.service';

const modelName = 'job-publish';
const API_URL_DEFAULT = process.env.REACT_APP_API;

const read = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${modelName}/?${query}`);
};

const getPlacementsandTypes = async () => {
	return axios.get(`${API_URL_DEFAULT}${modelName}/placements-and-types`, {
		// headers: await authHeader(),
	});
};

export default {
	read,
	getPlacementsandTypes,
};
