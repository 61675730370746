import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import DarkDataTable from '../../../../components/DarkDataTable';
import useDarkMode from '../../../../hooks/useDarkMode';
import Button from '../../../../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Textarea from '../../../../components/bootstrap/forms/Textarea';
import ProfileEmpModule from '../../../../modules/bakti/master/ProfileEmpModule';
import { MonthPicker } from '../components/DatePicker';
import { getCurrencyToValue, getValueToCurrency } from '../utils/format.utils';
import LoadingModal from '../../../../components/custom/LoadingModal';

const handleUpdate = (id, values) => {
	const newResponse = new Promise((resolve, reject) => {
		try {
			ProfileEmpModule.updateWorkExperience(id, values)
				.then((res) => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: res?.message ?? 'Data has been updated successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
	return newResponse;
};

const handleDelete = (id, values) => {
	// eslint-disable-next-line no-async-promise-executor
	const newResponse = new Promise(async (resolve, reject) => {
		try {
			Swal.fire({
				heightAuto: false,
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!',
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					ProfileEmpModule.updateWorkExperience(id, values)
						.then((res) => {
							Swal.fire({
								heightAuto: false,
								title: 'Information!',
								text: res?.message ?? 'Data has been deleted successfully',
								icon: 'success',
							});
							resolve({ error: false, message: 'successfully' });
						})
						.catch((err) => {
							Swal.fire({
								heightAuto: false,
								title: 'Warning!',
								text: err,
								icon: 'error',
							});
							reject(new Error(err));
						});
				}
			});
		} catch (e) {
			reject(new Error({ error: true }));
		}
	});
	return newResponse;
};

const FormCreate = ({
	initialValues,
	isOpen,
	setIsOpen,
	title,
	size,
	isUpdate,
	onSubmit,
	state,
}) => {
	const { darkModeStatus } = useDarkMode();

	const formik = useFormik({
		initialValues: { ...initialValues },
		validationSchema: Yup.object({
			company_name: Yup.string().required('Required'),
			bussiness_field: Yup.string().required('Required'),
			address: Yup.string().required('Required'),
			position: Yup.string().required('Required'),
			start_month: Yup.string().required('Required'),
			end_month: Yup.string().required('Required'),
			job_desc: Yup.string().required('Required'),
			achievment: Yup.string().required('Required'),
			superior_name: Yup.string().required('Required'),
			director_name: Yup.string().required('Required'),
		}),
		onReset: () => {
			setIsOpen(false);
		},
		onSubmit: (values, { setSubmitting, setErrors }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: 'Please check your entries !',
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes',
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						const new_data = {
							type: isUpdate ? 'update' : 'add',
							experience: JSON.stringify({
								company_name: values?.company_name,
								bussiness_field: values?.bussiness_field,
								address: values?.address,
								position: values?.position,
								start_month: values?.start_month,
								end_month: values?.end_month,
								job_desc: values?.job_desc,
								achievment: values?.achievment,
								superior_name: values?.superior_name,
								director_name: values?.director_name,
								current_income: values?.current_income,
								bonus_commission: values?.bonus_commission,
								other_allowance: values?.other_allowance,
								vehicle_allowance: values?.vehicle_allowance,
								last_salary_adjustment: values?.last_salary_adjustment,
							}),
							_id: values?._id,
						};

						onSubmit(new_data, state, setIsOpen);
					}
				});
			} catch (error) {
				setErrors({ submit: error.message });
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			} finally {
				setSubmitting(false);
			}
		},
	});

	const renderComponent = (
		<div className='row'>
			{/* Company */}
			<div className='col-4'>
				<div className='row' style={{ width: '100%' }}>
					<div className='col-md-12 my-2'>
						<h4>Company</h4>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='company_name' label='Company Name'>
							<Input
								data-testid='inputCompanyName'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.company_name}
								isValid={formik.isValid}
								isTouched={formik.touched.company_name}
								invalidFeedback={formik.errors.company_name}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='bussiness_field' label='Bussiness Field'>
							<Input
								data-testid='inputBussinessField'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.bussiness_field}
								isValid={formik.isValid}
								isTouched={formik.touched.bussiness_field}
								invalidFeedback={formik.errors.bussiness_field}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='address' label='Address'>
							<Input
								data-testid='inputAddress'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.address}
								isValid={formik.isValid}
								isTouched={formik.touched.address}
								invalidFeedback={formik.errors.address}
							/>
						</FormGroup>
					</div>
				</div>
			</div>

			{/* Job */}
			<div className='col-4'>
				<div className='row' style={{ width: '100%' }}>
					<div className='col-md-12 my-2'>
						<h4>Job</h4>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='position' label='Position'>
							<Input
								data-testid='inputPosition'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.position}
								isValid={formik.isValid}
								isTouched={formik.touched.position}
								invalidFeedback={formik.errors.position}
							/>
						</FormGroup>
					</div>
					<div className='col-md-6 my-2'>
						<FormGroup id='start_month' label='Start Month'>
							<MonthPicker
								data-testid='datepickerStartMonth'
								date={formik.values?.start_month}
								onChange={(value) => {
									if (!value) {
										formik.setFieldValue('start_month', '');
									} else {
										const _value = moment(value).format('YYYY-MM');
										formik.setFieldValue('start_month', _value);
									}
								}}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.start_month}
								invalidFeedback={formik.errors.start_month}
							/>
						</FormGroup>
					</div>
					<div className='col-md-6 my-2'>
						<FormGroup id='end_month' label='End Month'>
							<MonthPicker
								data-testid='datepickerEndMonth'
								date={formik.values?.end_month}
								onChange={(value) => {
									if (!value) {
										formik.setFieldValue('end_month', '');
									} else {
										const _value = moment(value).format('YYYY-MM');
										formik.setFieldValue('end_month', _value);
									}
								}}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.end_month}
								invalidFeedback={formik.errors.end_month}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='job_desc' label='Job Desc'>
							<Textarea
								data-testid='textareaJobDesc'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.job_desc}
								isValid={formik.isValid}
								isTouched={formik.touched.job_desc}
								invalidFeedback={formik.errors.job_desc}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='achievment' label='Achievement'>
							<Input
								data-testid='inputAchievement'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.achievment}
								isValid={formik.isValid}
								isTouched={formik.touched.achievment}
								invalidFeedback={formik.errors.achievment}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='superior_name' label='Superior Name'>
							<Input
								data-testid='inputSuperiorName'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.superior_name}
								isValid={formik.isValid}
								isTouched={formik.touched.superior_name}
								invalidFeedback={formik.errors.superior_name}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='director_name' label='Director Name'>
							<Input
								data-testid='inputDirectorName'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.director_name}
								isValid={formik.isValid}
								isTouched={formik.touched.director_name}
								invalidFeedback={formik.errors.director_name}
							/>
						</FormGroup>
					</div>
				</div>
			</div>

			{/* Income */}
			<div className='col-4'>
				<div className='row' style={{ width: '100%' }}>
					<div className='col-md-12 my-2'>
						<h4>Income</h4>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='current_income' label='Current Income'>
							<Input
								data-testid='inputCurrentincome'
								type='text'
								autoComplete='off'
								onChange={(e) => {
									const value = getCurrencyToValue(e.target.value);
									formik.setFieldValue('current_income', value);
								}}
								onBlur={formik.handleBlur}
								value={getValueToCurrency(formik.values.current_income)}
								isValid={formik.isValid}
								isTouched={formik.touched.current_income}
								invalidFeedback={formik.errors.current_income}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='bonus_commission' label='Bonus Commission'>
							<Input
								data-testid='inputBonusCommission'
								type='text'
								autoComplete='off'
								onChange={(e) => {
									const value = getCurrencyToValue(e.target.value);
									formik.setFieldValue('bonus_commission', value);
								}}
								onBlur={formik.handleBlur}
								value={getValueToCurrency(formik.values.bonus_commission)}
								isValid={formik.isValid}
								isTouched={formik.touched.bonus_commission}
								invalidFeedback={formik.errors.bonus_commission}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='other_allowance' label='Other Allowence'>
							<Input
								data-testid='inputOtherAllowance'
								type='text'
								autoComplete='off'
								onChange={(e) => {
									const value = getCurrencyToValue(e.target.value);
									formik.setFieldValue('other_allowance', value);
								}}
								onBlur={formik.handleBlur}
								value={getValueToCurrency(formik.values.other_allowance)}
								isValid={formik.isValid}
								isTouched={formik.touched.other_allowance}
								invalidFeedback={formik.errors.other_allowance}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='vehicle_allowance' label='Vehicle Allowance'>
							<Input
								data-testid='inputVehicleAllowance'
								type='text'
								autoComplete='off'
								onChange={(e) => {
									const value = getCurrencyToValue(e.target.value);
									formik.setFieldValue('vehicle_allowance', value);
								}}
								onBlur={formik.handleBlur}
								value={getValueToCurrency(formik.values.vehicle_allowance)}
								isValid={formik.isValid}
								isTouched={formik.touched.vehicle_allowance}
								invalidFeedback={formik.errors.vehicle_allowance}
							/>
						</FormGroup>
					</div>
					<div className='col-md-12 my-2'>
						<FormGroup id='last_salary_adjustment' label='Last Salary Adjustment'>
							<Input
								data-testid='inputLastSalaryAdjustment'
								type='text'
								autoComplete='off'
								onChange={(e) => {
									const value = getCurrencyToValue(e.target.value);
									formik.setFieldValue('last_salary_adjustment', value);
								}}
								onBlur={formik.handleBlur}
								value={getValueToCurrency(formik.values.last_salary_adjustment)}
								isValid={formik.isValid}
								isTouched={formik.touched.last_salary_adjustment}
								invalidFeedback={formik.errors.last_salary_adjustment}
							/>
						</FormGroup>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<Modal
			titleId='form-create'
			isOpen={isOpen}
			setIsOpen={formik.handleReset}
			size={size}
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={formik.handleReset}>
				<ModalTitle id='form-create'>{title}</ModalTitle>
			</ModalHeader>
			<div tag='form' noValidate onSubmit={formik.handleSubmit}>
				<ModalBody>{renderComponent}</ModalBody>
				<ModalFooter>
					<Button
						data-testid='buttonClose'
						color='info'
						type='reset'
						onClick={formik.handleReset}
						isLink
						className='mx-1'>
						Close
					</Button>

					<Button
						data-testid='buttonSave'
						icon='Save'
						color='info'
						type='submit'
						onClick={formik.handleSubmit}
						isLight={darkModeStatus}
						className='mx-1'>
						Save
					</Button>
				</ModalFooter>
			</div>
		</Modal>
	);
};

FormCreate.propTypes = {
	initialValues: PropTypes.oneOfType([PropTypes.object]),
	state: PropTypes.oneOfType([PropTypes.object]),
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	title: PropTypes.string,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
	isUpdate: PropTypes.bool,
	onSubmit: PropTypes.func,
};
FormCreate.defaultProps = {
	initialValues: null,
	state: null,
	isOpen: false,
	setIsOpen: () => false,
	title: 'Modal',
	size: 'xl',
	isUpdate: false,
	onSubmit: () => {},
};

const FormDetail = ({ data, isOpen, setIsOpen, title, size }) => {
	const renderComponent = (
		<div className='row'>
			{/* Company */}
			<div className='col-4'>
				<Card shadow='none' borderSize={1}>
					<CardBody className='d-flex flex-column'>
						<div>
							<h4>
								<b>Company</b>
							</h4>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Company Name</b>
							</div>
							<div className='col-6 my-1'>{data?.company_name}</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Business Field</b>
							</div>
							<div className='col-6 my-1'>{data?.bussiness_field}</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Address</b>
							</div>
							<div className='col-6 my-1'>{data?.address}</div>
						</div>
					</CardBody>
				</Card>
			</div>
			{/* Job */}
			<div className='col-4'>
				<Card shadow='none' borderSize={1}>
					<CardBody className='d-flex flex-column'>
						<div>
							<h4>
								<b>Job</b>
							</h4>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Position</b>
							</div>
							<div className='col-6 my-1'>{data?.position}</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Start - End</b>
							</div>
							<div className='col-6 my-1'>
								{moment(data?.start_month).format('MMMM YYYY')} -
								{moment(data?.end_month).format('MMMM YYYY')}
							</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Job Description</b>
							</div>
							<div className='col-6 my-1'>{data?.job_desc}</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Achievement</b>
							</div>
							<div className='col-6 my-1'>{data?.achievment}</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Superior Name</b>
							</div>
							<div className='col-6 my-1'>{data?.superior_name}</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Director Name</b>
							</div>
							<div className='col-6 my-1'>{data?.director_name}</div>
						</div>
					</CardBody>
				</Card>
			</div>
			{/* Income */}
			<div className='col-4'>
				<Card shadow='none' borderSize={1}>
					<CardBody className='d-flex flex-column'>
						<div>
							<h4>
								<b>Income</b>
							</h4>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Current Income</b>
							</div>
							<div className='col-6 my-1'>
								{getValueToCurrency(data?.current_income)}
							</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Bonus/Commision</b>
							</div>
							<div className='col-6 my-1'>
								{getValueToCurrency(data?.bonus_commission)}
							</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Other Allowance</b>
							</div>
							<div className='col-6 my-1'>
								{getValueToCurrency(data?.other_allowance)}
							</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Vehicle Allowance</b>
							</div>
							<div className='col-6 my-1'>
								{getValueToCurrency(data?.vehicle_allowance)}
							</div>
						</div>
						<div className='row'>
							<div className='col-6 my-1'>
								<b>Last Salary Adjustment</b>
							</div>
							<div className='col-6 my-1'>
								{getValueToCurrency(data?.last_salary_adjustment)}
							</div>
						</div>
					</CardBody>
				</Card>
			</div>
		</div>
	);

	return (
		<Modal
			titleId='form-create'
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			size={size}
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={setIsOpen}>
				<ModalTitle id='form-create'>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>{renderComponent}</ModalBody>
			<ModalFooter>
				<Button
					data-testid='buttonClose'
					color='info'
					type='reset'
					onClick={() => setIsOpen(false)}
					isLink
					className='mx-1'>
					Close
				</Button>
			</ModalFooter>
		</Modal>
	);
};

FormDetail.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object]),
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	title: PropTypes.string,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
};
FormDetail.defaultProps = {
	data: null,
	isOpen: false,
	setIsOpen: () => false,
	title: 'Modal',
	size: 'xl',
};

const ButtonCustom = ({ data, state, onRemove, onSubmit }) => {
	const { darkModeStatus } = useDarkMode();

	const [openEdit, setOpenEdit] = useState(false);
	const [openDetail, setOpenDetail] = useState(false);

	return (
		<div>
			<Button
				data-testid='buttonDetail'
				icon='Eye'
				color='primary'
				type='button'
				onClick={() => setOpenDetail(true)}
				isLight={darkModeStatus}
				className='mx-1'
			/>

			<Button
				data-testid='buttonEdit'
				icon='Edit'
				color='info'
				type='button'
				onClick={() => setOpenEdit(true)}
				isLight={darkModeStatus}
				className='mx-1'
			/>

			<Button
				data-testid='buttonDelete'
				icon='Delete'
				color='danger'
				type='button'
				onClick={() => onRemove({ type: 'remove', _id: data?.experience?._id }, state)}
				isLight={darkModeStatus}
				className='mx-1'
			/>

			<FormCreate
				initialValues={{ ...data?.experience }}
				isOpen={openEdit}
				setIsOpen={setOpenEdit}
				title='Edit Work Experience'
				isUpdate
				onSubmit={onSubmit}
			/>

			<FormDetail
				data={{ ...data?.experience }}
				isOpen={openDetail}
				setIsOpen={setOpenDetail}
				title='Work Experience Details'
			/>
		</div>
	);
};

ButtonCustom.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object]),
	state: PropTypes.oneOfType([PropTypes.object]),
	onRemove: PropTypes.func,
	onSubmit: PropTypes.func,
};
ButtonCustom.defaultProps = {
	data: null,
	state: {
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: false,
		filter: {},
	},
	onRemove: () => {},
	onSubmit: () => {},
};

const TableCustom = ({ state, onPageChange, onRemove, onSubmit }) => {
	const { darkModeStatus } = useDarkMode();

	const handlePageChange = (page) => {
		onPageChange({ ...state, curPage: page, reload: true });
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		const all = newPerPage === state.totalRows;
		onPageChange({ ...state, perPage: newPerPage, curPage: page, showAll: all, reload: true });
	};

	const paginationComponentOptions = {
		selectAllRowsItem: true,
		selectAllRowsItemText: 'ALL',
	};

	const columns = useMemo(
		() => [
			{
				name: ['No'],
				selector: (row, indexRow) =>
					state.perPage * state.curPage + indexRow - state.perPage + 1,
				sortable: false,
				width: '60px',
			},
			{
				name: ['Company Name'],
				selector: (row) => row?.experience?.company_name,
				sortable: false,
			},
			{
				name: ['Bussiness Field'],
				selector: (row) => row?.experience?.bussiness_field,
				sortable: false,
			},
			{
				name: ['Position'],
				selector: (row) => row?.experience?.position,
				sortable: false,
			},
			{
				name: ['Work Period'],
				selector: (row) => {
					const start = moment(row?.experience?.start_month).format('MMM YYYY');
					const end = moment(row?.experience?.end_month).format('MMM YYYY');
					return `${start} - ${end}`;
				},
				sortable: false,
			},
			{
				name: ['Action'],
				width: '180px',
				// eslint-disable-next-line react/no-unstable-nested-components
				cell: (row) => {
					return (
						<ButtonCustom
							data={row}
							state={state}
							onRemove={onRemove}
							onSubmit={onSubmit}
						/>
					);
				},
			},
		],
		[onRemove, onSubmit, state],
	);
	return (
		<DarkDataTable
			columns={columns}
			data={state.data}
			pagination
			paginationServer
			progressPending={state.loading}
			paginationTotalRows={state.totalRows}
			paginationResetDefaultPage={state.isReset}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			paginationComponentOptions={paginationComponentOptions}
			theme={darkModeStatus ? 'custom_dark' : 'light'}
		/>
	);
};

TableCustom.propTypes = {
	state: PropTypes.oneOfType([PropTypes.object]),
	onPageChange: PropTypes.func,
	onRemove: PropTypes.func,
	onSubmit: PropTypes.func,
};
TableCustom.defaultProps = {
	state: {
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: false,
		filter: {},
	},
	onPageChange: () => {},
	onRemove: () => {},
	onSubmit: () => {},
};

const TableWorkExperience = ({ id }) => {
	const { darkModeStatus } = useDarkMode();

	const [openCreate, setOpenCreate] = useState(false);
	const [loading, setLoading] = useState(false);

	const [state, setState] = useState({
		title: 'Work Experience',
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: true,
		filter: {},
	});

	const initialValues = {
		_id: id,
		company_name: '',
		bussiness_field: '',
		address: '',
		position: '',
		start_month: '',
		end_month: '',
		job_desc: '',
		achievment: '',
		superior_name: '',
		director_name: '',
		current_income: 0,
		bonus_commission: 0,
		other_allowance: 0,
		vehicle_allowance: 0,
		last_salary_adjustment: 0,
	};

	const updateSubmit = (values, _state, showForm) => {
		setLoading(true);

		handleUpdate(id, values)
			.then(() => {
				showForm(false);
				setState((e) => ({ ...e, ..._state, reload: true }));
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const deleteSubmit = (values, _state) => {
		handleDelete(id, values)
			.then(() => {
				setState((e) => ({ ...e, ..._state, reload: true }));
			})
			.catch(() => {})
			.finally(() => {});
	};

	useEffect(() => {
		const fetch = async () => {
			try {
				await fetchData(state.curPage, state.perPage, state.showAll);
			} catch (err) {
				//
			}
		};

		if (state.reload && id) fetch();

		return () => {
			//
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.reload, id]);

	const fetchData = async (curPage, perPage, showAll = false) => {
		setState((e) => ({ ...e, loading: true }));

		const query = {
			page: curPage,
			sizePerPage: perPage,
			showAll,
		};

		return ProfileEmpModule.readWorkExperience(id, query)
			.then((res) => {
				setState((e) => ({
					...e,
					data: res.foundData,
					totalRows: res.countData,
					curPage: res.currentPage,
					perPage,
				}));
			})
			.catch(() => {
				setState((e) => ({
					...e,
					data: [],
					totalRows: 0,
					curPage: 1,
					perPage: 0,
					showAll: false,
				}));
			})
			.finally(() => {
				setState((e) => ({ ...e, loading: false, reload: false }));
			});
	};

	return (
		<div>
			<Card shadow='sm'>
				<CardHeader size='sm' borderSize={1}>
					<CardLabel>
						<CardTitle>{state.title}</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody>
					<div className='d-flex justify-content-end my-1'>
						<div>
							<Button
								data-testid='buttonAddNew'
								icon='Add'
								color='info'
								type='button'
								isLight={darkModeStatus}
								onClick={() => setOpenCreate(true)}>
								Add New
							</Button>
						</div>
					</div>
					<div className='my-1'>
						<TableCustom
							state={state}
							onSubmit={updateSubmit}
							onPageChange={setState}
							onRemove={deleteSubmit}
						/>
					</div>
				</CardBody>
			</Card>

			<FormCreate
				initialValues={initialValues}
				isOpen={openCreate}
				setIsOpen={setOpenCreate}
				title='Add Work Experience'
				state={state}
				onSubmit={updateSubmit}
			/>

			<LoadingModal loading={loading} setLoading={setLoading} />
		</div>
	);
};

TableWorkExperience.propTypes = {
	id: PropTypes.string,
};
TableWorkExperience.defaultProps = {
	id: null,
};

export default TableWorkExperience;
