import React, { useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Label from '../../../../components/bootstrap/forms/Label';
import InputFileComponent from '../components/InputFileComponent';
import {
	DOCDownload,
	DOCDownloadObject,
	ImagePreview,
	PDFPreview,
	PDFPreviewObject,
} from '../components/FilePreview';
import FileModule from '../../../../modules/FileModule';
import LoadingModal from '../../../../components/custom/LoadingModal';

const MAX_FILE = 2 * 1024 * 1024;

const TableOnboarding = ({ initialValues, onSubmit }) => {
	const { darkModeStatus } = useDarkMode();

	const [openImagePreview, setOpenImagePreview] = useState(false);
	const [content, setContent] = useState(null);
	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: { ...initialValues },
		validate: (values) => {
			const errors = {};

			const requiredFields = [
				{
					name: 'interview_form_name',
					errorKey: 'interview_form_name',
					errorMessage: 'File Interview is required.',
				},
				{
					name: 'formal_photo_obj',
					errorKey: 'formal_photo_name',
					errorMessage: 'Formal photo is required.',
				},
				{
					name: 'kartu_keluarga_obj',
					errorKey: 'kartu_keluarga_name',
					errorMessage: 'Kartu keluarga is required.',
				},
				{
					name: 'ktp_number',
					errorKey: 'ktp_number',
					errorMessage: 'KTP is required.',
				},
				{
					name: 'bank_account_obj',
					errorKey: 'bank_account_name',
					errorMessage: 'Bank account name is required.',
				},
				// { name: 'npwp_obj', errorKey: 'npwp_name', errorMessage: 'NPWP is required.' },
				{
					name: 'bank_account_branch',
					errorKey: 'bank_account_branch',
					errorMessage: 'Bank Branch is required.',
				},
				{
					name: 'bank_account_number',
					errorKey: 'bank_account_number',
					errorMessage: 'Bank Acount Number is required.',
				},
			];

			requiredFields.forEach((field) => {
				if (!values?.[field.name]) {
					errors[field.errorKey] = field.errorMessage;
				} else if (values[field.name].size > MAX_FILE) {
					errors[field.errorKey] = 'File size exceeds the maximum limit (2MB).';
				}
			});

			formik.isValid = false;

			return errors;
		},
		onReset: () => {},
		onSubmit: (values, { setSubmitting, setErrors }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: 'Please check your entries !',
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes',
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						const new_data = {
							interview_form: {
								filename: values?.interview_form_obj?.name || '',
								onedrive_id: '',
							},
							formal_photo: {
								filename: values?.formal_photo_obj?.name || '',
								onedrive_id: '',
							},
							kartu_keluarga: {
								filename: values?.kartu_keluarga_obj?.name || '',
								onedrive_id: '',
							},
							bpjs_kesehatan: {
								filename: values?.bpjs_kesehatan_obj?.name || '',
								onedrive_id: '',
							},
							bpjs_ketenagakerjaan: {
								filename: values?.bpjs_ketenagakerjaan_obj?.name || '',
								onedrive_id: '',
							},
							bank_account: {
								filename: values?.bank_account_obj?.name || '',
								onedrive_id: '',
								bank_number: values?.bank_account_number || '',
								bank_branch: values?.bank_account_branch || '',
							},
							npwp: {
								filename: values?.npwp_obj?.name || '',
								onedrive_id: '',
							},
							ktp_number: values?.ktp_number || '',
							kjp_number: values?.kjp_number || '',
							registered_tax: values?.registered_tax || '',
							actual_tax: values?.actual_tax || '',
						};

						const formData = new FormData();
						formData.append('data', JSON.stringify(new_data));
						formData.append('interview_form', values.interview_form_obj);
						formData.append('formal_photo', values.formal_photo_obj);
						formData.append('kartu_keluarga', values.kartu_keluarga_obj);
						formData.append('bpjs_kesehatan', values.bpjs_kesehatan_obj);
						formData.append('bpjs_ketenagakerjaan', values.bpjs_ketenagakerjaan_obj);
						formData.append('bank_account', values.bank_account_obj);
						formData.append('npwp', values.npwp_obj);

						onSubmit(values?._id, formData);
					}
				});
			} catch (error) {
				setErrors({ submit: error.message });
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			} finally {
				setSubmitting(false);
			}
		},
	});

	const filePreview = (onedrive_id, object, filename) => {
		const file_img = ['image/jpg', 'image/jpeg', 'image/png'];
		const file_doc = [
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		];
		const file_pdf = ['application/pdf'];
		const file_xlx = [
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/wps-office.xlsx',
		];

		if (object && file_img.includes(object?.type)) {
			const reader = new FileReader();
			reader.onload = (event) => {
				setContent(event.target.result);
			};
			reader.readAsDataURL(object);
			setOpenImagePreview(true);
		} else if (object && file_doc.includes(object?.type)) {
			// FIXME:
		} else if (object && file_pdf.includes(object?.type)) {
			PDFPreviewObject(object);
		} else if (object && file_xlx.includes(object?.type)) {
			// FIXME:
			DOCDownloadObject(object);
		} else if (onedrive_id) {
			setLoading(true);

			FileModule.readByID(onedrive_id)
				.then((res) => {
					// images : png. jpg, jpeg
					if (['png', 'jpg', 'jpeg'].includes(res.extension)) {
						const img = `data:${res?.mimetype};base64,${res?.file}`;
						setContent(img);
						setOpenImagePreview(true);
					}
					// pdf
					else if (['pdf'].includes(res.extension)) {
						PDFPreview(res);
					}
					// doc : doc, docx
					else if (['doc', 'docx'].includes(res.extension)) {
						DOCDownload(res, filename);
					}
					// xls : xls, xlsx
					else if (['xls', 'xlsx'].includes(res.extension)) {
						DOCDownload(res, filename);
					}
				})
				.catch(() => {})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const renderComponent = (
		<div>
			<div className='row'>
				<div className='col-lg-4 col-sm-12 my-2'>
					<InputFileComponent
						data-testid='inputInterviewForm'
						id='interview_form_name'
						idField='interview_form_obj'
						label='Interview Form'
						formText='File must be in Excel format (.xlsx)'
						value={formik.values.interview_form_name}
						isValid={formik.isValid}
						isTouched={formik.touched.interview_form_name}
						invalidFeedback={formik.errors.interview_form_name}
						accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
						onChange={formik.handleChange}
						onChangeField={formik.setFieldValue}
						onError={formik.setFieldError}
						showButton={
							!!formik.values.interview_form_id || !!formik.values.interview_form_obj
						}
						btnClick={() =>
							filePreview(
								formik.values.interview_form_id,
								formik.values.interview_form_obj,
							)
						}
					/>
				</div>
				<div className='col-lg-4 col-sm-12 my-2'>
					<InputFileComponent
						data-testid='inputFormatPhoto'
						id='formal_photo_name'
						idField='formal_photo_obj'
						label='Formal Photo'
						formText='Max size 2MB with JPEG, PDF extention'
						value={formik.values.formal_photo_name}
						isValid={formik.isValid}
						isTouched={formik.touched.formal_photo_name}
						invalidFeedback={formik.errors.formal_photo_name}
						accept='.jpeg, .pdf'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						onChangeField={formik.setFieldValue}
						onError={formik.setFieldError}
						showButton={
							!!formik.values.formal_photo_id || !!formik.values.formal_photo_obj
						}
						btnClick={() =>
							filePreview(
								formik.values.formal_photo_id,
								formik.values.formal_photo_obj,
							)
						}
					/>
				</div>
			</div>

			<div className='col-12 border-bottom my-2' />

			<div className='row'>
				<div className='col-lg-4 col-sm-12 my-2'>
					<FormGroup id='ktp_number' label='KTP Number' formText=''>
						<Input
							data-testid='inputKTPNumber'
							type='text'
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.ktp_number}
							isValid={formik.isValid}
							isTouched={formik.touched.ktp_number}
							invalidFeedback={formik.errors.ktp_number}
						/>
					</FormGroup>
				</div>
				<div className='col-lg-4 col-sm-12 my-2'>
					<InputFileComponent
						data-testid='inputKartuKeluarga'
						id='kartu_keluarga_name'
						idField='kartu_keluarga_obj'
						label='KK (Kartu Keluarga)'
						formText='Max size 2MB with JPEG, PDF extention'
						value={formik.values.kartu_keluarga_name}
						isValid={formik.isValid}
						isTouched={formik.touched.kartu_keluarga_name}
						invalidFeedback={formik.errors.kartu_keluarga_name}
						accept='.jpeg, .pdf'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						onChangeField={formik.setFieldValue}
						onError={formik.setFieldError}
						showButton={
							!!formik.values.kartu_keluarga_id || !!formik.values.kartu_keluarga_obj
						}
						btnClick={() =>
							filePreview(
								formik.values.kartu_keluarga_id,
								formik.values.kartu_keluarga_obj,
							)
						}
					/>
				</div>
			</div>

			<div className='row'>
				<div className='col-lg-4 col-sm-12 my-2'>
					<InputFileComponent
						data-testid='inputBankAccountName'
						id='bank_account_name'
						idField='bank_account_obj'
						label='Bank Account'
						formText='Max size 2MB with JPEG, PDF extention'
						value={formik.values.bank_account_name}
						isValid={formik.isValid}
						isTouched={formik.touched.bank_account_name}
						invalidFeedback={formik.errors.bank_account_name}
						accept='.jpeg, .pdf'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						onChangeField={formik.setFieldValue}
						onError={formik.setFieldError}
						showButton={
							!!formik.values.bank_account_id || !!formik.values.bank_account_obj
						}
						btnClick={() =>
							filePreview(
								formik.values.bank_account_id,
								formik.values.bank_account_obj,
							)
						}
					/>
				</div>
				<div className='col-lg-4 col-sm-12 my-2'>
					<FormGroup id='bank_account_number' label='Bank Account Number' formText=''>
						<Input
							data-testid='inputBankAccountNumber'
							type='text'
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.bank_account_number}
							isValid={formik.isValid}
							isTouched={formik.touched.bank_account_number}
							invalidFeedback={formik.errors.bank_account_number}
							required
						/>
					</FormGroup>
				</div>
				<div className='col-lg-4 col-sm-12 my-2'>
					<FormGroup id='bank_account_branch' label='Bank Branch' formText=''>
						<Input
							data-testid='inputBankAccountBranch'
							type='text'
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.bank_account_branch}
							isValid={formik.isValid}
							isTouched={formik.touched.bank_account_branch}
							invalidFeedback={formik.errors.bank_account_branch}
						/>
					</FormGroup>
				</div>
			</div>

			<div className='row'>
				<div className='col-lg-4 col-sm-12 my-2'>
					<InputFileComponent
						data-testid='inputBPJSKesehatan'
						id='bpjs_kesehatan_name'
						idField='bpjs_kesehatan_obj'
						label='BPJS Kesehatan'
						formText='Max size 2MB with JPEG, PDF extention'
						value={formik.values.bpjs_kesehatan_name}
						isValid={formik.isValid}
						isTouched={formik.touched.bpjs_kesehatan_name}
						invalidFeedback={formik.errors.bpjs_kesehatan_name}
						accept='.jpeg, .pdf'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						onChangeField={formik.setFieldValue}
						onError={formik.setFieldError}
						showButton={
							!!formik.values.bpjs_kesehatan_id || !!formik.values.bpjs_kesehatan_obj
						}
						btnClick={() =>
							filePreview(
								formik.values.bpjs_kesehatan_id,
								formik.values.bpjs_kesehatan_obj,
							)
						}
					/>
				</div>
				<div className='col-lg-4 col-sm-12 my-2'>
					<InputFileComponent
						data-testid='inputBPJSKetenagakerjaan'
						id='bpjs_ketenagakerjaan_name'
						idField='bpjs_ketenagakerjaan_obj'
						label='BPJS Ketenagakerjaan'
						formText='Max size 2MB with JPEG, PDF extention'
						value={formik.values.bpjs_ketenagakerjaan_name}
						isValid={formik.isValid}
						isTouched={formik.touched.bpjs_ketenagakerjaan_name}
						invalidFeedback={formik.errors.bpjs_ketenagakerjaan_name}
						accept='.jpeg, .pdf'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						onChangeField={formik.setFieldValue}
						onError={formik.setFieldError}
						showButton={
							!!formik.values.bpjs_ketenagakerjaan_id ||
							!!formik.values.bpjs_ketenagakerjaan_obj
						}
						btnClick={() =>
							filePreview(
								formik.values.bpjs_ketenagakerjaan_id,
								formik.values.bpjs_ketenagakerjaan_obj,
							)
						}
					/>
				</div>
				<div className='col-lg-4 col-sm-12 my-2'>
					<FormGroup id='kjp_number' label='KJP Number' formText=''>
						<Input
							data-testid='inputKJPNumber'
							type='text'
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.kjp_number}
							isValid={formik.isValid}
							isTouched={formik.touched.kjp_number}
							invalidFeedback={formik.errors.kjp_number}
						/>
					</FormGroup>
				</div>
			</div>

			<div className='col-12 border-bottom my-2' />

			<div className='row'>
				<div className='col-lg-4 col-sm-12 my-2'>
					<InputFileComponent
						data-testid='inputNPWPName'
						id='npwp_name'
						idField='npwp_obj'
						label='NPWP'
						formText='Max size 2MB with JPEG, PDF extention'
						value={formik.values.npwp_name}
						isValid={formik.isValid}
						isTouched={formik.touched.npwp_name}
						invalidFeedback={formik.errors.npwp_name}
						accept='.jpeg, .pdf'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						onChangeField={formik.setFieldValue}
						onError={formik.setFieldError}
						showButton={!!formik.values.npwp_id || !!formik.values.npwp_obj}
						btnClick={() => filePreview(formik.values.npwp_id, formik.values.npwp_obj)}
					/>
				</div>
				<div className='col-lg-4 col-sm-12 my-2'>
					<FormGroup id='registered_tax' label='Registration TAX' formText=''>
						<Input
							data-testid='inputRegisteredTax'
							type='text'
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.registered_tax}
							isValid={formik.isValid}
							isTouched={formik.touched.registered_tax}
							invalidFeedback={formik.errors.registered_tax}
						/>
					</FormGroup>
				</div>
				<div className='col-lg-4 col-sm-12 my-2'>
					<FormGroup id='actual_tax' label='Actual TAX' formText=''>
						<Input
							data-testid='inputActualTax'
							type='text'
							autoComplete='off'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.actual_tax}
							isValid={formik.isValid}
							isTouched={formik.touched.actual_tax}
							invalidFeedback={formik.errors.actual_tax}
						/>
					</FormGroup>
				</div>
			</div>

			<div className='row'>
				<div className='col-sm-12 my-2'>
					<Label>Status Profile</Label>
				</div>
				<div className='col-sm-12 my-2 ms-4'>{formik.values.status ?? '-'}</div>
			</div>
		</div>
	);

	return (
		<Card stretch tag='form' noValidate onSubmit={formik.handleSubmit}>
			<CardHeader
				size='sm'
				borderSize={1}
				style={{ backgroundColor: darkModeStatus ? '#4d69fa33' : '#edf0ff' }}>
				<CardLabel icon='Contacts' iconColor='info'>
					<CardTitle>Onboarding Data</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody isScrollable>{renderComponent}</CardBody>
			<CardFooter>
				<CardFooterRight>
					{formik.values.status !== 'Approved' && (
						<Button
							data-testid='buttonSubmit'
							type='submit'
							icon='Save'
							color='info'
							isDisable={!formik.isValid && formik.submitCount}
							isLight={darkModeStatus}>
							Save
						</Button>
					)}
				</CardFooterRight>
			</CardFooter>

			<ImagePreview isOpen={openImagePreview} setIsOpen={setOpenImagePreview} img={content} />

			<LoadingModal loading={loading} setLoading={setLoading} />
		</Card>
	);
};

TableOnboarding.propTypes = {
	initialValues: PropTypes.oneOfType([PropTypes.object]),
	onSubmit: PropTypes.func,
};
TableOnboarding.defaultProps = {
	initialValues: null,
	onSubmit: () => {},
};

export default TableOnboarding;
