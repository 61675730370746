import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import DarkDataTable from '../../../../components/DarkDataTable';
import useDarkMode from '../../../../hooks/useDarkMode';
import Button from '../../../../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import Input from '../../../../components/bootstrap/forms/Input';
import ProfileEmpModule from '../../../../modules/bakti/master/ProfileEmpModule';
import LoadingModal from '../../../../components/custom/LoadingModal';
import ProficiencyModule from '../../../../modules/bakti/master/ProficiencyModule';

const handleUpdate = (id, values) => {
	const newResponse = new Promise((resolve, reject) => {
		try {
			ProfileEmpModule.updateLanguage(id, values)
				.then((res) => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: res?.message ?? 'Data has been updated successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
	return newResponse;
};

const handleDelete = (id, values) => {
	// eslint-disable-next-line no-async-promise-executor
	const newResponse = new Promise(async (resolve, reject) => {
		try {
			Swal.fire({
				heightAuto: false,
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!',
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					ProfileEmpModule.updateLanguage(id, values)
						.then((res) => {
							Swal.fire({
								heightAuto: false,
								title: 'Information!',
								text: res?.message ?? 'Data has been deleted successfully',
								icon: 'success',
							});
							resolve({ error: false, message: 'successfully' });
						})
						.catch((err) => {
							Swal.fire({
								heightAuto: false,
								title: 'Warning!',
								text: err,
								icon: 'error',
							});
							reject(new Error(err));
						});
				}
			});
		} catch (e) {
			reject(new Error({ error: true }));
		}
	});
	return newResponse;
};

const FormCreate = ({
	initialValues,
	listProficiency,
	isOpen,
	setIsOpen,
	title,
	size,
	isUpdate,
	onSubmit,
	state,
}) => {
	const { darkModeStatus } = useDarkMode();

	const formik = useFormik({
		initialValues: { ...initialValues },
		validationSchema: Yup.object({
			name: Yup.string().required('Required'),
			writing_proficiency: Yup.string().required('Required'),
			speaking_proficiency: Yup.string().required('Required'),
		}),
		onReset: () => {
			setIsOpen(false);
		},
		onSubmit: (values, { setSubmitting, setErrors }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: 'Please check your entries !',
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes',
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						const new_data = {
							type: isUpdate ? 'update' : 'add',
							language: JSON.stringify({
								name: values.name,
								writing_proficiency: values.writing_proficiency,
								speaking_proficiency: values.speaking_proficiency,
							}),
							_id: values?._id,
						};

						onSubmit(new_data, state, setIsOpen);
					}
				});
			} catch (error) {
				setErrors({ submit: error.message });
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			} finally {
				setSubmitting(false);
			}
		},
	});

	const renderComponent = (
		<div className='row'>
			<div className='col-md-12 my-2'>
				<FormGroup id='name' label='Name' isFloating>
					<Input
						data-testid='inputName'
						type='text'
						autoComplete='off'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.name}
						isValid={formik.isValid}
						isTouched={formik.touched.name}
						invalidFeedback={formik.errors.name}
					/>
				</FormGroup>
			</div>
			<div className='col-md-12 my-2'>
				<FormGroup id='writing_proficiency' label='Writing Proficiency' isFloating>
					<Select
						data-testid='selectWritingProficiency'
						list={listProficiency}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.writing_proficiency}
						isValid={formik.isValid}
						isTouched={formik.touched.writing_proficiency}
						invalidFeedback={formik.errors.writing_proficiency}
					/>
				</FormGroup>
			</div>
			<div className='col-md-12 my-2'>
				<FormGroup id='speaking_proficiency' label='Speaking Proficiency' isFloating>
					<Select
						data-testid='selectSpeakingProficiency'
						list={listProficiency}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.speaking_proficiency}
						isValid={formik.isValid}
						isTouched={formik.touched.speaking_proficiency}
						invalidFeedback={formik.errors.speaking_proficiency}
					/>
				</FormGroup>
			</div>
		</div>
	);

	return (
		<Modal
			titleId='form-create'
			isOpen={isOpen}
			setIsOpen={formik.handleReset}
			size={size}
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={formik.handleReset}>
				<ModalTitle id='form-create'>{title}</ModalTitle>
			</ModalHeader>
			<div tag='form' noValidate onSubmit={formik.handleSubmit}>
				<ModalBody>{renderComponent}</ModalBody>
				<ModalFooter>
					<Button
						data-testid='buttonClose'
						color='info'
						type='reset'
						onClick={formik.handleReset}
						isLink
						className='mx-1'>
						Close
					</Button>

					<Button
						data-testid='buttonSave'
						icon='Save'
						color='info'
						type='submit'
						onClick={formik.handleSubmit}
						isLight={darkModeStatus}
						className='mx-1'>
						Save
					</Button>
				</ModalFooter>
			</div>
		</Modal>
	);
};

FormCreate.propTypes = {
	initialValues: PropTypes.oneOfType([PropTypes.object]),
	listProficiency: PropTypes.oneOfType([PropTypes.array]),
	state: PropTypes.oneOfType([PropTypes.object]),
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	title: PropTypes.string,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
	isUpdate: PropTypes.bool,
	onSubmit: PropTypes.func,
};
FormCreate.defaultProps = {
	initialValues: null,
	listProficiency: [],
	state: null,
	isOpen: false,
	setIsOpen: () => false,
	title: 'Modal',
	size: null,
	isUpdate: false,
	onSubmit: () => {},
};

const ButtonCustom = ({ data, listProficiency, state, onRemove, onSubmit }) => {
	const { darkModeStatus } = useDarkMode();

	const [openEdit, setOpenEdit] = useState(false);

	return (
		<div>
			<Button
				data-testid='buttonEdit'
				icon='Edit'
				color='info'
				type='button'
				onClick={() => setOpenEdit(true)}
				isLight={darkModeStatus}
				className='mx-1'
			/>

			<Button
				data-testid='buttonDelete'
				icon='Delete'
				color='danger'
				type='button'
				onClick={() => onRemove({ type: 'remove', _id: data?.language?._id }, state)}
				isLight={darkModeStatus}
				className='mx-1'
			/>

			<FormCreate
				initialValues={{ ...data?.language }}
				listProficiency={listProficiency}
				isOpen={openEdit}
				setIsOpen={setOpenEdit}
				title='Edit Language'
				isUpdate
				onSubmit={onSubmit}
			/>
		</div>
	);
};

ButtonCustom.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object]),
	listProficiency: PropTypes.oneOfType([PropTypes.array]),
	state: PropTypes.oneOfType([PropTypes.object]),
	onRemove: PropTypes.func,
	onSubmit: PropTypes.func,
};
ButtonCustom.defaultProps = {
	data: null,
	listProficiency: [],
	state: {
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: false,
		filter: {},
	},
	onRemove: () => {},
	onSubmit: () => {},
};

const TableCustom = ({ state, listProficiency, onPageChange, onRemove, onSubmit }) => {
	const { darkModeStatus } = useDarkMode();

	const handlePageChange = (page) => {
		onPageChange({ ...state, curPage: page, reload: true });
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		const all = newPerPage === state.totalRows;
		onPageChange({ ...state, perPage: newPerPage, curPage: page, showAll: all, reload: true });
	};

	const paginationComponentOptions = {
		selectAllRowsItem: true,
		selectAllRowsItemText: 'ALL',
	};

	const columns = useMemo(
		() => [
			{
				name: ['No'],
				selector: (row, indexRow) =>
					state.perPage * state.curPage + indexRow - state.perPage + 1,
				sortable: false,
				width: '60px',
			},
			{
				name: ['Name'],
				selector: (row) => row?.language?.name,
				sortable: false,
			},
			{
				name: ['Writing Proficiency'],
				selector: (row) => row?.language?.writing_proficiency,
				sortable: false,
			},
			{
				name: ['Speaking Proficiency'],
				selector: (row) => row?.language?.speaking_proficiency,
				sortable: false,
			},
			{
				name: ['Action'],
				width: '140px',
				// eslint-disable-next-line react/no-unstable-nested-components
				cell: (row) => {
					return (
						<ButtonCustom
							data={row}
							listProficiency={listProficiency}
							state={state}
							onRemove={onRemove}
							onSubmit={onSubmit}
						/>
					);
				},
			},
		],
		[listProficiency, onRemove, onSubmit, state],
	);
	return (
		<DarkDataTable
			columns={columns}
			data={state.data}
			pagination
			paginationServer
			progressPending={state.loading}
			paginationTotalRows={state.totalRows}
			paginationResetDefaultPage={state.isReset}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			paginationComponentOptions={paginationComponentOptions}
			theme={darkModeStatus ? 'custom_dark' : 'light'}
		/>
	);
};

TableCustom.propTypes = {
	state: PropTypes.oneOfType([PropTypes.object]),
	listProficiency: PropTypes.oneOfType([PropTypes.array]),
	onPageChange: PropTypes.func,
	onRemove: PropTypes.func,
	onSubmit: PropTypes.func,
};
TableCustom.defaultProps = {
	state: {
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: false,
		filter: {},
	},
	listProficiency: [],
	onPageChange: () => {},
	onRemove: () => {},
	onSubmit: () => {},
};

const TableLanguage = ({ id }) => {
	const { darkModeStatus } = useDarkMode();

	const [openCreate, setOpenCreate] = useState(false);
	const [loading, setLoading] = useState(false);

	const [state, setState] = useState({
		title: 'Language',
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: true,
		filter: {},
	});

	const [listProficiency, setListProficiency] = useState([]);

	const initialValues = {
		_id: id,
		name: '',
		writing_proficiency: null,
		speaking_proficiency: null,
	};

	const updateSubmit = (values, _state, showForm) => {
		setLoading(true);

		handleUpdate(id, values)
			.then(() => {
				showForm(false);
				setState((e) => ({ ...e, ..._state, reload: true }));
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	const deleteSubmit = (values, _state) => {
		handleDelete(id, values)
			.then(() => {
				setState((e) => ({ ...e, ..._state, reload: true }));
			})
			.catch(() => {})
			.finally(() => {});
	};

	useEffect(() => {
		const fetch = async () => {
			try {
				await fetchDataProficiency();
			} catch (err) {
				//
			}
		};

		fetch();

		return () => {
			//
		};
	}, []);

	useEffect(() => {
		const fetch = async () => {
			try {
				await fetchData(state.curPage, state.perPage, state.showAll);
			} catch (err) {
				//
			}
		};

		if (state.reload && id) fetch();

		return () => {
			//
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.reload, id]);

	const fetchData = async (curPage, perPage, showAll = false) => {
		setState((e) => ({ ...e, loading: true }));

		const query = {
			page: curPage,
			sizePerPage: perPage,
			showAll,
		};

		return ProfileEmpModule.readLanguage(id, query)
			.then((res) => {
				setState((e) => ({
					...e,
					data: res.foundData,
					totalRows: res.countData,
					curPage: res.currentPage,
					perPage,
				}));
			})
			.catch(() => {
				setState((e) => ({
					...e,
					data: [],
					totalRows: 0,
					curPage: 1,
					perPage: 0,
					showAll: false,
				}));
			})
			.finally(() => {
				setState((e) => ({ ...e, loading: false, reload: false }));
			});
	};

	const fetchDataProficiency = async () => {
		const query = { showAll: true };

		return ProficiencyModule.read(query)
			.then((res) => {
				const newList = [...res.foundData].map((e) => ({
					value: e?.level,
					label: e?.level,
				}));
				setListProficiency(newList);
			})
			.catch(() => {
				setListProficiency([]);
			})
			.finally(() => {});
	};

	return (
		<div>
			<Card shadow='sm'>
				<CardHeader size='sm' borderSize={1}>
					<CardLabel>
						<CardTitle>{state.title}</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody>
					<div className='d-flex justify-content-end my-1'>
						<div>
							<Button
								data-testid='buttonAddNew'
								icon='Add'
								color='info'
								type='button'
								isLight={darkModeStatus}
								onClick={() => setOpenCreate(true)}>
								Add New
							</Button>
						</div>
					</div>
					<div className='my-1'>
						<TableCustom
							state={state}
							listProficiency={listProficiency}
							onSubmit={updateSubmit}
							onRemove={deleteSubmit}
							onPageChange={setState}
						/>
					</div>
				</CardBody>
			</Card>

			<FormCreate
				initialValues={initialValues}
				listProficiency={listProficiency}
				isOpen={openCreate}
				setIsOpen={setOpenCreate}
				title='Add Language'
				state={state}
				onSubmit={updateSubmit}
			/>

			<LoadingModal loading={loading} setLoading={setLoading} />
		</div>
	);
};

TableLanguage.propTypes = {
	id: PropTypes.string,
};
TableLanguage.defaultProps = {
	id: null,
};

export default TableLanguage;
