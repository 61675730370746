import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import useDarkMode from '../../../../hooks/useDarkMode';
import Nav, { NavItem } from '../../../../components/bootstrap/Nav';
import TableSkill from './TableSkill';
import TableLanguage from './TableLanguage';

const FormSkillLanguage = ({ id, isLoading }) => {
	const { darkModeStatus } = useDarkMode();

	const TABS = {
		SKILL: 'Skill',
		LANGUAGE: 'Language',
	};
	const [tab, setTab] = useState(TABS.SKILL);

	return (
		<Card stretch>
			<CardHeader
				size='sm'
				borderSize={1}
				style={{ backgroundColor: darkModeStatus ? '#4d69fa33' : '#edf0ff' }}>
				<CardLabel icon='Contacts' iconColor='info'>
					<CardTitle>Skill & Language</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<Nav className='px-1' design='pills'>
					{Object.values(TABS).map((value, index) => (
						<NavItem
							data-testid={'nav'.concat(value)}
							key={'index'.concat(index)}
							onClick={() => setTab(value)}
							isActive={tab === value}>
							<div>{value}</div>
						</NavItem>
					))}
				</Nav>

				<div className='mb-2' />

				{tab === TABS.SKILL && <TableSkill id={id} isLoading={isLoading} />}

				{tab === TABS.LANGUAGE && <TableLanguage id={id} isLoading={isLoading} />}
			</CardBody>
		</Card>
	);
};

FormSkillLanguage.propTypes = {
	id: PropTypes.string,
	isLoading: PropTypes.bool,
};
FormSkillLanguage.defaultProps = {
	id: null,
	isLoading: true,
};

export default FormSkillLanguage;
