import React from 'react';
import PropTypes from 'prop-types';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import useDarkMode from '../../../../hooks/useDarkMode';
import TableWorkExperience from './TableWorkExperience';

const FormSkillLanguage = ({ id, isLoading }) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<Card stretch>
			<CardHeader
				size='sm'
				borderSize={1}
				style={{ backgroundColor: darkModeStatus ? '#4d69fa33' : '#edf0ff' }}>
				<CardLabel icon='ContentPaste' iconColor='info'>
					<CardTitle>Work Experience</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<TableWorkExperience id={id} isLoading={isLoading} />
			</CardBody>
		</Card>
	);
};

FormSkillLanguage.propTypes = {
	id: PropTypes.string,
	isLoading: PropTypes.bool,
};
FormSkillLanguage.defaultProps = {
	id: null,
	isLoading: true,
};

export default FormSkillLanguage;
