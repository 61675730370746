import { React, useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { decode } from 'html-entities';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Card, { CardBody } from '../../components/bootstrap/Card';
import JobAppliedModule from '../../modules/sodiq/JobAppliedModule';
import Button from '../../components/bootstrap/Button';
import Icon from '../../components/icon/Icon';
import Modal, { ModalBody } from '../../components/bootstrap/Modal';
import PageLayoutHeader from '../../pages/common/Headers/PageLayoutHeader';
import PaginationButtons, { PER_COUNT } from '../../components/PaginationButtons';
import { getRequester } from '../../helpers/helpers';
import RecruitmentProcess from './RecruitmentProcess';

const ModalLoading = (dt) => {
	const { loading, setLoading } = dt;
	return (
		<Modal isOpen={loading} size='sm' isCentered setIsOpen={setLoading} isStaticBackdrop>
			<ModalBody
				style={{ backgroundColor: '#6c5dd3', color: 'white' }}
				className='text-center'>
				<button className='btn btn-primary' type='button' disabled>
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span className='sr-only'>Loading...</span>
				</button>
			</ModalBody>
		</Modal>
	);
};

const Main = () => {
	const [loading, setLoading] = useState(false);

	const [data, setData] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const [page, setPage] = useState(1);
	const [isDetail, setIsDetail] = useState(null);
	const [detail, setDetail] = useState({});
	const [timeline, setTimeline] = useState([]);
	const [activeItemIndex, setActiveItemIndex] = useState(0);
	const { email } = getRequester();

	useEffect(() => {
		fetchData(page, perPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, perPage]);

	const fetchData = async (nPage, nPerPage = 10) => {
		setLoading(true);
		const params = {
			page: nPage,
			sizePerPage: nPerPage,
			email,
		};
		return JobAppliedModule.read(new URLSearchParams(params))
			.then((res) => {
				setData(res.foundData);
				setTotalRows(res.countData);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleDetail = (job) => {
		const timelineArr = [];
		if (job?.timeline?.applied) {
			timelineArr.push({
				label: 'Applied',
				status: job?.timeline?.applied.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.applied.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.applied.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.cv_screening) {
			timelineArr.push({
				label: 'CV Screening',
				status: job?.timeline?.cv_screening.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.cv_screening.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.cv_screening.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.hr_interview) {
			timelineArr.push({
				label: 'HR Interview',
				status: job?.timeline?.hr_interview.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.hr_interview.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.hr_interview.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.psikotest) {
			timelineArr.push({
				label: 'Psikotest',
				status: job?.timeline?.psikotest.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.psikotest.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.psikotest.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.user_interview) {
			timelineArr.push({
				label: 'User Interview',
				status: job?.timeline?.user_interview.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.user_interview.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.user_interview.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.offering) {
			timelineArr.push({
				label: 'Offering',
				status: job?.timeline?.offering.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.offering.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.offering.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.mcu) {
			timelineArr.push({
				label: 'MCU',
				status: job?.timeline?.mcu.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.mcu.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.mcu.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.onboarding) {
			timelineArr.push({
				label: 'Onboarding',
				status: job?.timeline?.onboarding.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.onboarding.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.onboarding.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		setActiveItemIndex(0);
		setTimeline([...timelineArr]);
		setDetail(job?.detail_job_publish);
		setIsDetail(true);
	};

	return (
		<PageWrapper title='Job Applied'>
			<ModalLoading loading={loading} setLoading={setLoading} />
			<PageLayoutHeader />
			{isDetail && (
				<header className='header header-back'>
					<div className='container-fluid'>
						<div className='row d-flex align-items-center'>
							<div className='row'>
								<div className='col-12'>
									<Button
										icon='ArrowBack'
										onClick={() => {
											setIsDetail(false);
										}}>
										Back
									</Button>
								</div>
							</div>
						</div>
					</div>
				</header>
			)}
			<Page container='fluid'>
				<div className='row'>
					{isDetail ? (
						<div className='col-12 d-flex mb-3 align-items-center justify-content-center'>
							<div className='flex-column rounded w-75 p-4 border'>
								<RecruitmentProcess
									timeline={timeline}
									activeItemIndex={activeItemIndex}
									setActiveItemIndex={setActiveItemIndex}
								/>
								<Card className='w-100'>
									<CardBody>
										<div className='row'>
											<div className='col-12'>
											<h3>{parse(decode(detail?.job_title))}</h3>
											</div>
										</div>
										<div className='row'>
											<div className='col-12'>
												<Icon icon='HomeWork' color='dark' />{' '}
												{detail?.placement}
												<Icon
													icon='BusinessCenter'
													color='dark'
													style={{ marginLeft: '15px' }}
												/>{' '}
												{detail?.status_emp}
											</div>
										</div>
										<div className='row mt-3'>
											<div className='col-12'>
												<h5>Description</h5>
											</div>
										</div>
										<div className='row'>
											<div className='col-12'>
											<p>{parse(decode(detail?.recruit_message))}</p>
											</div>
										</div>
										<div className='row mt-2'>
											<div className='col-12'>
												<h5>Responsibilities</h5>
											</div>
										</div>
										<div className='row'>
											<div className='col-12'>
												<p>{parse(decode(detail?.job_desc))}</p>
											</div>
										</div>
										<div className='row mt-2'>
											<div className='col-12'>
												<h5>Requirement</h5>
											</div>
										</div>
										<div className='row'>
											<div className='col-12'>
												<p>
													<ul>
														{detail?.educational_level?.map((item) => (
															<li>{decode(item)}</li>
														))}
														<li>{decode(detail?.field_study)}</li>
														<li>
															<p>{decode(detail?.skill)}</p>
														</li>
														<li>
															{detail?.work_exp
																? `Work Expirience min ${detail?.work_exp?.min} max ${detail?.work_exp?.max}`
																: ''}
														</li>
													</ul>
												</p>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					) : (
						<div className='col-12 d-flex mb-3 align-items-center justify-content-center'>
							<div className='flex-column rounded w-75 p-4 border'>
								{data.map((job) => {
									return (
										<Card className='w-100'>
											<CardBody>
												<div className='row'>
													<div className='col-12'>
														<h3>
															{decode(job?.detail_job_publish?.job_title)}
														</h3>
													</div>
												</div>
												<div className='row mt-3'>
													<div className='col-6'>
														<Icon icon='HomeWork' color='dark' />{' '}
														{job?.detail_job_publish?.placement}
														<Icon
															icon='BusinessCenter'
															color='dark'
															style={{ marginLeft: '15px' }}
														/>{' '}
														{job?.detail_job_publish?.status_emp}
													</div>
													<div className='col-6'>
														<Button
															onClick={() => {
																handleDetail(job);
															}}
															color='info'
															isLight
															className='float-end'>
															View Details{' '}
															<Icon icon='KeyboardArrowRight' />
														</Button>
													</div>
												</div>
											</CardBody>
										</Card>
									);
								})}

								<PaginationButtons
									data={new Array(totalRows)}
									label='items'
									setCurrentPage={setPage}
									currentPage={page}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
							</div>
						</div>
					)}
				</div>
			</Page>
		</PageWrapper>
	);
};
export default Main;
