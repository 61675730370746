import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/tyo/CRUD')),
};

const Portal = {
	JobPublish: lazy(() => import('../views/sodiq/JobPublish')),
	JobApplied: lazy(() => import('../views/sodiq/JobApplied')),
};

const Profile = {
	Profile: lazy(() => import('../views/bakti/profile/Profile')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <Portal.JobPublish />,
		index: 'JobPublish',
		exact: true,
	},
	{
		path: '/profile',
		element: <Profile.Profile />,
		index: 'Profile',
		exact: true,
	},
	{
		path: null,
		element: <Portal.JobApplied />,
		index: 'JobApplied',
		exact: true,
	},
];

export default contents;
