import React, { useEffect } from 'react';
import classNames from 'classnames';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Label from '../../components/bootstrap/forms/Label';

const RecruitmentProcess = (dt) => {
	const { timeline, activeItemIndex, setActiveItemIndex } = dt;
	const useAnimate = true;

	useEffect(() => {
		/* eslint-disable-next-line no-plusplus */
		for (let i = 0; i < timeline.length; i++) {
			if (i > activeItemIndex && timeline[i]?.status) {
				if (useAnimate) {
					setTimeout(() => {
						setActiveItemIndex(i);
					}, 300);
					break;
				} else {
					setActiveItemIndex(i);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeItemIndex, timeline]);

	const color = 'success';
	const { themeStatus } = useDarkMode();

	return (
		<Card>
			<CardBody className='p-5'>
				<div className='row'>
					<div className='col-12'>
						<h3 className='text-center'>Recruitment Process</h3>
					</div>
				</div>
				{timeline.length > 0 && (
					<div className='row mt-5'>
						<div className='col-12'>
							<div className='wizard-progress position-relative'>
								<div className='progress'>
									<div
										className={classNames('progress-bar', {
											[`bg-${color}`]: color !== 'primary',
										})}
										role='progressbar'
										style={{
											width: `${
												(100 / (timeline.length - 1)) * activeItemIndex
											}%`,
										}}
										aria-valuenow={
											(100 / (timeline.length - 1)) * activeItemIndex
										}
										aria-valuemin='0'
										aria-valuemax='100'
										aria-label='progress'
									/>
								</div>
								{timeline?.map((item, index) => (
									<>
										<Button
											type='button'
											className={classNames(
												'wizard-progress-btn',
												'position-absolute p-0 top-0',
												'translate-middle',
												'btn btn-sm',
												{
													[`btn-${item.color}`]: activeItemIndex >= index,
													[`btn-${themeStatus}`]: activeItemIndex < index,
												},
												'rounded-pill',
											)}
											style={{
												left: `${(100 / (timeline.length - 1)) * index}%`,
												height: '20px',
												width: '20px',
											}}
										/>
										<Label
											className='position-absolute text-center'
											style={{
												left: `${(100 / (timeline.length - 1)) * index}%`,
												marginLeft: `-${item.label.length * 3 + 5}px`,
												marginTop: '12px',
											}}>
											{item?.label}
										</Label>
									</>
								))}
							</div>
						</div>
					</div>
				)}
			</CardBody>
		</Card>
	);
};
export default RecruitmentProcess;
