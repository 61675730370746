import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import ProfileEmpModule from '../../../../modules/bakti/master/ProfileEmpModule';
import ReligionsModule from '../../../../modules/bakti/master/ReligionsModule';
import GenderModule from '../../../../modules/bakti/master/GenderModule';
import NationalitiesModule from '../../../../modules/bakti/master/NationalitiesModule';
import TableBiodata from './TableBiodata';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import useDarkMode from '../../../../hooks/useDarkMode';
import Spinner from '../../../../components/bootstrap/Spinner';
import LoadingModal from '../../../../components/custom/LoadingModal';

const handleUpdate = (id, values) => {
	const newResponse = new Promise((resolve, reject) => {
		try {
			ProfileEmpModule.updateBiodata(id, values)
				.then((res) => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: res?.message ?? 'Data has been updated successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
	return newResponse;
};

const FormBiodata = ({ id, isLoading }) => {
	const { darkModeStatus } = useDarkMode();

	const initValues = {
		// key : profile
		fullname: '',
		nickname: '',
		phone_number: '',
		personal_email: '',
		birth_date: '',
		birth_city: '',
		religion: null,
		gender: null,
		domicile: '',
		ktp_address: '',
		nationality: null,
		photo: '',
		photo_obj: null,

		// key : emergency_contacts
		contacts: [],
	};

	const [init, setInit] = useState(initValues);

	const [loading, setLoading] = useState(false);
	const [reload, setReload] = useState(true);
	const [progress, setProgress] = useState(false);

	const [listReligions, setListReligions] = useState([]);
	const [listGender, setListGender] = useState([]);
	const [listNationalities, setListNationalities] = useState([]);

	const updateSubmit = (values) => {
		setLoading(true);

		handleUpdate(id, values)
			.then(() => {
				setReload(true);
			})
			.catch(() => {
				setLoading(false);
			})
			.finally(() => {});
	};

	useEffect(() => {
		const fetch = async () => {
			try {
				await fetchDataBiodata(id);
				await fetchDataReligion();
				await fetchDataGender();
				await fetchDataNationality();
			} catch (err) {
				//
			}
		};

		if (reload && id) fetch();

		return () => {
			//
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reload, id]);

	const fetchDataBiodata = async (_id) => {
		setLoading(true);
		setProgress(false);

		return ProfileEmpModule.readBiodata(_id)
			.then((response) => {
				setInit((e) => ({
					...e,
					...response,
					religion: !response?.religion
						? null
						: { value: response?.religion, label: response?.religion },
					gender: !response?.gender
						? null
						: { value: response?.gender, label: response?.gender },
					nationality: !response?.nationality
						? null
						: { value: response?.nationality, label: response?.nationality },
				}));
			})
			.catch(() => {
				setInit(initValues);
			})
			.finally(() => {
				setLoading(false);
				setReload(false);
				setProgress(true);
			});
	};

	const fetchDataReligion = async () => {
		const query = { showAll: true };
		return ReligionsModule.read(query)
			.then((res) => {
				const new_list = [...res.foundData].map((e) => ({
					value: e?.name,
					label: e?.name,
				}));
				setListReligions(new_list);
			})
			.catch(() => {
				setListReligions([]);
			})
			.finally(() => {});
	};

	const fetchDataGender = async () => {
		const query = { showAll: true };
		return GenderModule.read(query)
			.then((res) => {
				const new_list = [...res.foundData].map((e) => ({
					value: e?.name,
					label: e?.name,
				}));
				setListGender(new_list);
			})
			.catch(() => {
				setListGender([]);
			})
			.finally(() => {});
	};

	const fetchDataNationality = async () => {
		const query = { showAll: true };
		return NationalitiesModule.read(query)
			.then((res) => {
				const new_list = [...res.foundData].map((e) => ({
					value: e?.name,
					label: e?.name,
				}));
				setListNationalities(new_list);
			})
			.catch(() => {
				setListNationalities([]);
			})
			.finally(() => {});
	};

	if (!progress || isLoading)
		return (
			<Card stretch>
				<CardHeader
					size='sm'
					borderSize={1}
					style={{ backgroundColor: darkModeStatus ? '#4d69fa33' : '#edf0ff' }}>
					<CardLabel icon='Person' iconColor='info'>
						<CardTitle>Account Details</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody>
					<div style={{ display: 'grid', placeItems: 'center' }}>
						<Spinner color='info' size='8rem' />
					</div>
				</CardBody>
			</Card>
		);

	return (
		<>
			<TableBiodata
				initialValues={init}
				loading={loading}
				listGender={listGender}
				listNationalities={listNationalities}
				listReligions={listReligions}
				onSubmit={updateSubmit}
			/>

			<LoadingModal loading={loading} />
		</>
	);
};

FormBiodata.propTypes = {
	id: PropTypes.string,
	isLoading: PropTypes.bool,
};
FormBiodata.defaultProps = {
	id: null,
	isLoading: true,
};

export default FormBiodata;
