import React, { forwardRef, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../../../components/bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';

export const YearPicker = ({ date, btnColor, btnIcon, btnSize, onChange }) => {
	const { darkModeStatus } = useDarkMode();

	const [startDate, setStartDate] = useState(new Date());

	// eslint-disable-next-line react/prop-types
	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<Button
			color={btnColor}
			icon={btnIcon}
			size={btnSize}
			onClick={onClick}
			isLight={darkModeStatus}
			ref={ref}>
			{value}
		</Button>
	));

	useEffect(() => {
		setStartDate(date);
	}, [date]);

	return (
		<DatePicker
			selected={startDate}
			onChange={(value) => {
				setStartDate(value);
				onChange(value);
			}}
			customInput={<ExampleCustomInput />}
			showYearPicker
			dateFormat='yyyy'
		/>
	);
};

YearPicker.propTypes = {
	date: PropTypes.oneOfType([PropTypes.object]),
	btnColor: PropTypes.string,
	btnIcon: PropTypes.string,
	btnSize: PropTypes.oneOf(['sm', null, 'lg']),
	onChange: PropTypes.func,
};
YearPicker.defaultProps = {
	date: new Date(),
	btnColor: 'info',
	btnIcon: '',
	btnSize: null,
	onChange: () => {},
};

export const MonthPicker = ({
	date,
	onChange,
	invalidFeedback,
	isRequired,
	className,
	placeholder,
}) => {
	const [startDate, setStartDate] = useState(null);

	useEffect(() => {
		console.log(date);
		if (!date) setStartDate(null);
		else {
			const datetime = moment(date).toDate();
			console.log(datetime);
			setStartDate(datetime);
		}
	}, [date]);

	return (
		<>
			<DatePicker
				selected={startDate}
				onChange={(value) => onChange(value)}
				className={classNames(
					'form-control',
					'form-control-md',
					invalidFeedback && 'is-invalid',
					className,
				)}
				showMonthYearPicker
				dateFormat='yyyy-MM'
				required={isRequired}
				placeholderText={placeholder}
			/>
			{invalidFeedback && (
				<div
					style={{
						width: '100%',
						marginTop: '.25rem',
						fontSize: '80%',
						color: ' #dc3545',
					}}>
					{invalidFeedback}
				</div>
			)}
		</>
	);
};

MonthPicker.propTypes = {
	date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	onChange: PropTypes.func,
	className: PropTypes.string,
	invalidFeedback: PropTypes.string,
	isRequired: PropTypes.bool,
	placeholder: PropTypes.string,
};
MonthPicker.defaultProps = {
	date: new Date(),
	onChange: () => {},
	className: null,
	invalidFeedback: null,
	isRequired: false,
	placeholder: 'YYYY-MM',
};

const Index = ({
	name,
	value,
	date_format,
	onChange,
	invalidFeedback,
	isRequired,
	className,
	placeholder,
	is_submitted,
	invalidMessage,
	readOnly,
	showMonthYearPicker,
	showYearPicker,
}) => {
	const [dateF] = useState(date_format || 'yyyy-mm-dd');
	const [defaultFormat] = useState('YYYY-MM-DDTHH:mm:ssZ');
	const [valueDate, setValueDate] = useState(null);

	useEffect(() => {
		if (value && moment(value).isValid()) {
			const checkString = typeof value === 'string' && value.search('Z');
			const valFo = checkString > 0 ? defaultFormat : 'YYYY-MM-DD';
			const newValue = moment(value, valFo).tz('Asia/Jakarta').format('YYYY-MM-DD');

			setValueDate(newValue);
		} else {
			setValueDate(null);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<>
			<DatePicker
				selected={(valueDate && new Date(valueDate)) || null}
				onChange={(val) => onChange(val, name)}
				placeholderText={placeholder}
				dateFormat={dateF}
				className={classNames(
					'form-control form-control-md',
					invalidFeedback && 'is-invalid',
					className,
				)}
				required={isRequired}
				ariaRequired={isRequired}
				ariaInvalid
				disabledKeyboardNavigation
				readOnly={readOnly}
				showMonthYearPicker={showMonthYearPicker}
				showYearPicker={showYearPicker}
			/>
			{invalidFeedback && is_submitted && (
				<div
					style={{
						width: '100%',
						marginTop: '.25rem',
						fontSize: '80%',
						color: ' #dc3545',
					}}>
					{invalidMessage}
				</div>
			)}
		</>
	);
};

Index.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	date_format: PropTypes.string,
	onChange: PropTypes.func,
	invalidFeedback: PropTypes.string,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	invalidMessage: PropTypes.string,
	isRequired: PropTypes.bool,
	is_submitted: PropTypes.bool,
	readOnly: PropTypes.bool,
	showMonthYearPicker: PropTypes.bool,
	showYearPicker: PropTypes.bool,
};
Index.defaultProps = {
	name: null,
	value: null,
	date_format: 'yyyy-mm-dd',
	onChange: () => {},
	invalidFeedback: null,
	className: null,
	placeholder: 'YYYY-MM-DD',
	isRequired: false,
	is_submitted: false,
	readOnly: false,
	invalidMessage: '',
	showMonthYearPicker: false,
	showYearPicker: false,
};

export default Index;
