import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import DarkDataTable from '../../../../components/DarkDataTable';
import useDarkMode from '../../../../hooks/useDarkMode';
import Button from '../../../../components/bootstrap/Button';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import ProfileEmpModule from '../../../../modules/bakti/master/ProfileEmpModule';
import FileModule from '../../../../modules/FileModule';
import { DOCDownload, ImagePreview, PDFPreview } from '../components/FilePreview';
import LoadingModal from '../../../../components/custom/LoadingModal';

const MAX_FILE = 2 * 1024 * 1024;

const handleUpdate = (id, values) => {
	const newResponse = new Promise((resolve, reject) => {
		try {
			ProfileEmpModule.updateFileDocument(id, values)
				.then((res) => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: res?.message ?? 'Data has been updated successfully',
						icon: 'success',
					});
					resolve({ error: false, message: 'successfully' });
				})
				.catch((err) => {
					Swal.fire({ heightAuto: false, title: 'Warning!', text: err, icon: 'error' });
					reject(new Error(err));
				});
		} catch (e) {
			reject(new Error(e.message));
		}
	});
	return newResponse;
};

const handleDelete = (id, values) => {
	// eslint-disable-next-line no-async-promise-executor
	const newResponse = new Promise(async (resolve, reject) => {
		try {
			Swal.fire({
				heightAuto: false,
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!',
				reverseButtons: true,
			}).then((result) => {
				if (result.isConfirmed) {
					const new_data = {
						key: values?.documents?.key,
						filename: '',
						onedrive_id: '',
					};

					const formData = new FormData();
					formData.append('data', JSON.stringify(new_data));

					ProfileEmpModule.updateFileDocument(id, formData)
						.then((res) => {
							Swal.fire({
								heightAuto: false,
								title: 'Information!',
								text: res?.message ?? 'Data has been deleted successfully',
								icon: 'success',
							});
							resolve({ error: false, message: 'successfully' });
						})
						.catch((err) => {
							Swal.fire({
								heightAuto: false,
								title: 'Warning!',
								text: err,
								icon: 'error',
							});
							reject(new Error(err));
						});
				}
			});
		} catch (e) {
			reject(new Error({ error: true }));
		}
	});
	return newResponse;
};

const FormCreate = ({ initialValues, isOpen, setIsOpen, title, size, onSubmit }) => {
	const { darkModeStatus } = useDarkMode();

	const formik = useFormik({
		initialValues: { ...initialValues, file: '', file_obj: null },
		validate: (values) => {
			const errors = {};

			if (!values?.file) errors.file = 'Required';
			if (values?.file_obj && values?.file_obj?.size > MAX_FILE) {
				errors.file = 'File size exceeds the maximum limit (2MB).';
			}

			return errors;
		},
		onReset: () => {
			setIsOpen(false);
		},
		onSubmit: (values, { setSubmitting, setErrors }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: 'Please check your entries !',
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes',
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						const new_data = {
							key: values?.key,
							filename: values?.file_obj?.name || '',
							onedrive_id: '',
						};

						const formData = new FormData();
						formData.append('data', JSON.stringify(new_data));
						formData.append('file', values.file_obj);

						onSubmit(formData);
					}
				});
			} catch (error) {
				setErrors({ submit: error.message });
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			} finally {
				setSubmitting(false);
			}
		},
	});

	const renderComponent = (
		<div className='row'>
			<div className='col-md-12 my-2'>
				<FormGroup id='file' isFloating formText='Max size 2MB with JPEG or PDF extension'>
					<Input
						data-testid='inputFile'
						type='file'
						onChange={(e) => {
							const file = e?.target?.files?.[0] || null;
							formik.setFieldValue('file_obj', file);
							formik.handleChange(e);
						}}
						onBlur={formik.handleBlur}
						value={formik.values.file}
						isValid={formik.isValid}
						isTouched={!!formik.touched.file}
						invalidFeedback={formik.errors.file}
						accept='.jpeg, .pdf'
					/>
				</FormGroup>
			</div>
		</div>
	);

	return (
		<Modal
			titleId='form-create'
			isOpen={isOpen}
			setIsOpen={formik.handleReset}
			size={size}
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={formik.handleReset}>
				<ModalTitle id='form-create'>{title}</ModalTitle>
			</ModalHeader>
			<div tag='form' noValidate onSubmit={formik.handleSubmit}>
				<ModalBody>{renderComponent}</ModalBody>
				<ModalFooter>
					<Button
						data-testid='buttonClose'
						color='info'
						type='reset'
						onClick={formik.handleReset}
						isLink
						className='mx-1'>
						Close
					</Button>

					<Button
						data-testid='buttonSave'
						icon='Save'
						color='info'
						type='submit'
						onClick={formik.handleSubmit}
						isLight={darkModeStatus}
						className='mx-1'>
						Save
					</Button>
				</ModalFooter>
			</div>
		</Modal>
	);
};

FormCreate.propTypes = {
	initialValues: PropTypes.oneOfType([PropTypes.object]),
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	title: PropTypes.string,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
	onSubmit: PropTypes.func,
};
FormCreate.defaultProps = {
	initialValues: null,
	isOpen: false,
	setIsOpen: () => false,
	title: 'Modal',
	size: null,
	onSubmit: () => {},
};

const ButtonCustom = ({ data, state, onRemove, onSubmit }) => {
	const { darkModeStatus } = useDarkMode();

	const [openEdit, setOpenEdit] = useState(false);
	const [openImagePreview, setOpenImagePreview] = useState(false);
	const [content, setContent] = useState(null);
	const [loading, setLoading] = useState(false);

	const filePreview = (onedrive_id) => {
		setLoading(true);

		FileModule.readByID(onedrive_id)
			.then((res) => {
				// images : png. jpg, jpeg
				if (['png', 'jpg', 'jpeg'].includes(res.extension)) {
					const img = `data:${res?.mimetype};base64,${res?.file}`;
					setContent(img);
					setOpenImagePreview(true);
				}
				// pdf
				else if (['pdf'].includes(res.extension)) {
					PDFPreview(res);
				}
				// doc : doc, docx
				else if (['doc', 'docx'].includes(res.extension)) {
					DOCDownload(res);
				}
				// xls : xls, xlsx
				else if (['xls', 'xlsx'].includes(res.extension)) {
					DOCDownload(res);
				}
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div>
			<Button
				data-testid='buttonDetail'
				icon='Eye'
				color='primary'
				type='button'
				onClick={() => filePreview(data?.documents?.onedrive_id)}
				isLight={darkModeStatus}
				className={classNames('mx-1', !data?.documents?.onedrive_id && 'd-none')}
			/>

			<Button
				data-testid='buttonEdit'
				icon='Edit'
				color='info'
				type='button'
				onClick={() => setOpenEdit(true)}
				isLight={darkModeStatus}
				className='mx-1'
			/>

			<Button
				data-testid='buttonDelete'
				icon='Delete'
				color='danger'
				type='button'
				onClick={() => onRemove(data, state)}
				isLight={darkModeStatus}
				className={classNames('mx-1', !data?.documents?.onedrive_id && 'd-none')}
			/>

			<FormCreate
				initialValues={{ ...data?.documents }}
				isOpen={openEdit}
				setIsOpen={setOpenEdit}
				title='Edit Files / Documents'
				isUpdate
				onSubmit={onSubmit}
			/>

			<ImagePreview isOpen={openImagePreview} setIsOpen={setOpenImagePreview} img={content} />

			<LoadingModal loading={loading} setLoading={setLoading} />
		</div>
	);
};

ButtonCustom.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object]),
	state: PropTypes.oneOfType([PropTypes.object]),
	onRemove: PropTypes.func,
	onSubmit: PropTypes.func,
};
ButtonCustom.defaultProps = {
	data: null,
	state: {
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: false,
		filter: {},
	},
	onRemove: () => {},
	onSubmit: () => {},
};

const TableCustom = ({ state, onPageChange, onRemove, onSubmit }) => {
	const { darkModeStatus } = useDarkMode();

	const handlePageChange = (page) => {
		onPageChange({ ...state, curPage: page });
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		const all = newPerPage === state.totalRows;
		onPageChange({ ...state, perPage: newPerPage, curPage: page, showAll: all });
	};

	const paginationComponentOptions = {
		selectAllRowsItem: true,
		selectAllRowsItemText: 'ALL',
	};

	const columns = useMemo(
		() => [
			{
				name: ['No'],
				selector: (row, indexRow) =>
					state.perPage * state.curPage + indexRow - state.perPage + 1,
				sortable: false,
				width: '60px',
			},
			{
				name: ['File / Document Name'],
				selector: (row) => row?.documents?.name,
				sortable: false,
			},
			{
				name: ['Action'],
				width: '180px',
				// eslint-disable-next-line react/no-unstable-nested-components
				cell: (row) => {
					return (
						<ButtonCustom
							data={row}
							state={state}
							onRemove={onRemove}
							onSubmit={onSubmit}
						/>
					);
				},
			},
		],
		[onRemove, onSubmit, state],
	);
	return (
		<DarkDataTable
			columns={columns}
			data={state.data}
			pagination
			paginationServer
			progressPending={state.loading}
			paginationTotalRows={state.totalRows}
			paginationResetDefaultPage={state.isReset}
			onChangeRowsPerPage={handlePerRowsChange}
			onChangePage={handlePageChange}
			paginationComponentOptions={paginationComponentOptions}
			theme={darkModeStatus ? 'custom_dark' : 'light'}
		/>
	);
};

TableCustom.propTypes = {
	state: PropTypes.oneOfType([PropTypes.object]),
	onPageChange: PropTypes.func,
	onRemove: PropTypes.func,
	onSubmit: PropTypes.func,
};
TableCustom.defaultProps = {
	state: {
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: false,
		filter: {},
	},
	onPageChange: () => {},
	onRemove: () => {},
	onSubmit: () => {},
};

const TableFileDocument = ({ id }) => {
	const [state, setState] = useState({
		title: 'Files / Documents',
		data: [],
		totalRows: 0,
		perPage: 10,
		curPage: 1,
		showAll: false,
		isReset: false,
		loading: false,
		reload: true,
		filter: {},
	});

	const [loadingModal, setLoadingModal] = useState(false);

	const updateSubmit = (values, _state) => {
		setLoadingModal(true);

		handleUpdate(id, values)
			.then(() => {
				setState((e) => ({ ...e, ..._state, reload: true }));
			})
			.catch(() => {
				setLoadingModal(false);
			})
			.finally(() => {});
	};

	const deleteSubmit = (values, _state) => {
		setLoadingModal(true);

		handleDelete(id, values)
			.then(() => {
				setState((e) => ({ ...e, ..._state, reload: true }));
			})
			.catch(() => {
				setLoadingModal(false);
			})
			.finally(() => {});
	};

	useEffect(() => {
		const fetch = async () => {
			try {
				await fetchData(state.curPage, state.perPage, state.showAll);
			} catch (err) {
				//
			}
		};

		if (state.reload && id) fetch();

		return () => {
			//
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.reload, id]);

	const fetchData = async (curPage, perPage, showAll = false) => {
		setState((e) => ({ ...e, loading: true }));

		const query = {
			page: curPage,
			sizePerPage: perPage,
			showAll,
		};

		return ProfileEmpModule.readFileDocument(id, query)
			.then((res) => {
				setState((e) => ({
					...e,
					data: res.foundData,
					totalRows: res.countData,
					curPage: res.currentPage,
					perPage,
				}));
			})
			.catch(() => {
				setState((e) => ({
					...e,
					data: [],
					totalRows: 0,
					curPage: 1,
					perPage: 0,
					showAll: false,
				}));
			})
			.finally(() => {
				setState((e) => ({ ...e, loading: false, reload: false }));
				setLoadingModal(false);
			});
	};

	return (
		<div>
			<Card shadow='sm'>
				<CardHeader size='sm' borderSize={1}>
					<CardLabel>
						<CardTitle>{state.title}</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody>
					<div className='my-1'>
						<TableCustom
							state={state}
							onSubmit={updateSubmit}
							onRemove={deleteSubmit}
						/>
					</div>
				</CardBody>
			</Card>

			<LoadingModal loading={loadingModal} />
		</div>
	);
};

TableFileDocument.propTypes = {
	id: PropTypes.string,
};
TableFileDocument.defaultProps = {
	id: null,
};

export default TableFileDocument;
