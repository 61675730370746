import Service from '../services/file.service';

const readByID = (onedrive_id) => {
	return Service.readByID(onedrive_id).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default { readByID };
