import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Label from '../../../../components/bootstrap/forms/Label';
import InputGroup from '../../../../components/bootstrap/forms/InputGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Button from '../../../../components/bootstrap/Button';
import FormText from '../../../../components/bootstrap/forms/FormText';
import useDarkMode from '../../../../hooks/useDarkMode';

const InputFileComponent = ({
	id,
	label,
	formText,
	value,
	isValid,
	isTouched,
	invalidFeedback,
	accept,
	onChange,
	onBlur,
	onChangeField,
	idField,
	showButton,
	btnClick,
	optionsText,
}) => {
	const { darkModeStatus } = useDarkMode();

	return (
		<FormGroup>
			<Label htmlFor={id}>
				{label} <span style={{ color: '#dc3545' }}>{optionsText}</span>
			</Label>
			<InputGroup id={id}>
				<Input
					id={id}
					type='file'
					onChange={(e) => {
						onChange(e);
						const file = e?.target?.files?.[0] || null;
						onChangeField(idField, file);
					}}
					onBlur={onBlur}
					value={value}
					isValid={isValid}
					isTouched={isTouched}
					invalidFeedback={invalidFeedback}
					accept={accept}
				/>
				<Button
					icon='Eye'
					color='info'
					onClick={btnClick}
					isLight={darkModeStatus}
					className={classNames(!showButton && 'd-none')}
				/>
			</InputGroup>
			<FormText id={`${id}-text`}>{formText}</FormText>
		</FormGroup>
	);
};

InputFileComponent.propTypes = {
	id: PropTypes.string,
	idField: PropTypes.string,
	label: PropTypes.string,
	formText: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
	isValid: PropTypes.bool,
	isTouched: PropTypes.bool,
	invalidFeedback: PropTypes.string,
	accept: PropTypes.string,
	showButton: PropTypes.bool,
	optionsText: PropTypes.string,
	btnClick: PropTypes.func,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onChangeField: PropTypes.func,
};
InputFileComponent.defaultProps = {
	id: 'id',
	idField: 'id_obj',
	label: 'label',
	formText: '',
	value: undefined,
	isTouched: false,
	isValid: false,
	invalidFeedback: null,
	accept: '.jpg, .jpeg, .pdf',
	showButton: false,
	optionsText: null,
	btnClick: () => {},
	onChange: () => {},
	onBlur: () => {},
	onChangeField: () => {},
};

export default InputFileComponent;
