import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderRight } from '../../../layout/Header/Header';
import USERS from '../../../common/data/userDummyData';
import { getRequester } from '../../../helpers/helpers';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = () => {
	const { i18n } = useTranslation();
	const { person_name } = getRequester();

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	return (
		<HeaderRight>
			<div className='row g-3'>
				<div className='col-auto'>
					<div className='user-info text-end'>
						<div className='user-name d-flex align-items-center'>
							{`${person_name} `}
						</div>
						<div className='user-sub-title'>Candidat</div>
					</div>
				</div>
				<div className='col-auto'>
					<div className='user-avatar'>
						<img
							srcSet={USERS.JOHN.srcSet}
							src={USERS.JOHN.src}
							alt='Avatar'
							width={128}
							height={128}
						/>
					</div>
				</div>
			</div>
		</HeaderRight>
	);
};

export default CommonHeaderRight;
