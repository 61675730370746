import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API;
const appName = process.env.REACT_APP_NAME;
const publicKey = process.env.REACT_APP_PUBLIC_KEY;

const login = async (username, password) => {
	return axios
		.post(`${API_URL}auth/signin`, { username, password }, { headers: await authHeader() })
		.then(async (response) => {
			return response;
		});
};

const generateToken = async () => {
	const payload = {
		'x-public-key': publicKey,
		'x-app-name': appName,
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	};
	return axios
		.post(
			`${API_URL}auth/app-token`,
			{},
			{
				headers: payload,
			},
		)
		.then(async (response) => {
			if (response.data.appToken) {
				localStorage.setItem('appToken', JSON.stringify(response.data));
			}

			return response;
		});
};
const logout = () => {
	localStorage.clear();
	return window.location.reload();
};

const register = async (payload) => {
	return axios.post(`${API_URL}auth/signup`, payload, { headers: await authHeader() });
};

const getMenu = async (role) => {
	return axios
		.get(`${API_URL}authorization/${role}`, { headers: await authHeader() })
		.then((response) => {
			localStorage.setItem('menu', JSON.stringify(response.data));
			return response.data;
		});
};

const forgotPassword = async (payload) => {
	return axios.post(`${API_URL}auth/forgot-password`, payload, { headers: await authHeader() });
};

const verifyOtp = async (payload) => {
	return axios.post(`${API_URL}auth/verify-otp`, payload, { headers: await authHeader() });
};

const checkVerify = async (payload) => {
	return axios.get(`${API_URL}auth/check-verify?${payload}`, { headers: await authHeader() });
};

export default {
	login,
	logout,
	register,
	getMenu,
	generateToken,
	forgotPassword,
	verifyOtp,
	checkVerify,
};
