import axios from 'axios';
import authHeader from '../auth-header';

const modelName = 'job-applied';
const API_URL_DEFAULT = process.env.REACT_APP_API;

const read = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${modelName}/?${query}`, { headers: await authHeader() });
};

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${modelName}`, payload, { headers: await authHeader() });
};

const checkProfile = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${modelName}/check-profile?${query}`, {
		headers: await authHeader(),
	});
};

export default {
	read,
	create,
	checkProfile,
};
