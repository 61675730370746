export const getValueToCurrency = (value) => {
	const _value = parseFloat(value?.toString()?.replace(/[^0-9]/g, '') || '0');
	let _ammount = '';

	if (!Number.isNaN(_value)) {
		_ammount = _value.toLocaleString('id-ID', {
			style: 'currency',
			currency: 'IDR',
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		});
	} else {
		_ammount = '';
	}

	return _ammount;
};

export const getCurrencyToValue = (value) => {
	return parseFloat(value?.toString()?.replace(/[^0-9]/g, '') || '0');
};

export default {};
