import axios from 'axios';
import authHeader from '../auth-header.service';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const read = async (query) => {
	return axios.get(`${API_URL_DEFAULT}profile`, {
		headers: await authHeader(),
		params: query,
	});
};

const readByID = async (id, query) => {
	return axios.get(`${API_URL_DEFAULT}profile/${id}`, {
		headers: await authHeader(),
		params: query,
	});
};

const readByUserID = async (id, query) => {
	return axios.get(`${API_URL_DEFAULT}profile/user/${id}`, {
		headers: await authHeader(),
		params: query,
	});
};

const readBiodata = async (id, query) => {
	return axios.get(`${API_URL_DEFAULT}profile/${id}/biodata`, {
		headers: await authHeader(),
		params: query,
	});
};

const updateBiodata = async (id, payload) => {
	return axios.put(`${API_URL_DEFAULT}profile/${id}/biodata`, payload, {
		headers: await authHeader(),
	});
};

const readEducationFormal = async (id, query) => {
	return axios.get(`${API_URL_DEFAULT}profile/${id}/educations/formal`, {
		headers: await authHeader(),
		params: query,
	});
};

const updateEducationFormal = async (id, payload) => {
	return axios.put(`${API_URL_DEFAULT}profile/${id}/educations/formal`, payload, {
		headers: await authHeader(),
	});
};

const readEducationInformal = async (id, query) => {
	return axios.get(`${API_URL_DEFAULT}profile/${id}/educations/informal`, {
		headers: await authHeader(),
		params: query,
	});
};

const updateEducationInformal = async (id, payload) => {
	return axios.put(`${API_URL_DEFAULT}profile/${id}/educations/informal`, payload, {
		headers: await authHeader(),
	});
};

const readSkill = async (id, query) => {
	return axios.get(`${API_URL_DEFAULT}profile/${id}/skill`, {
		headers: await authHeader(),
		params: query,
	});
};

const updateSkill = async (id, payload) => {
	return axios.put(`${API_URL_DEFAULT}profile/${id}/skill`, payload, {
		headers: await authHeader(),
	});
};

const readLanguage = async (id, query) => {
	return axios.get(`${API_URL_DEFAULT}profile/${id}/language`, {
		headers: await authHeader(),
		params: query,
	});
};

const updateLanguage = async (id, payload) => {
	return axios.put(`${API_URL_DEFAULT}profile/${id}/language`, payload, {
		headers: await authHeader(),
	});
};

const readWorkExperience = async (id, query) => {
	return axios.get(`${API_URL_DEFAULT}profile/${id}/work-experience`, {
		headers: await authHeader(),
		params: query,
	});
};

const updateWorkExperience = async (id, payload) => {
	return axios.put(`${API_URL_DEFAULT}profile/${id}/work-experience`, payload, {
		headers: await authHeader(),
	});
};

const readFileDocument = async (id, query) => {
	return axios.get(`${API_URL_DEFAULT}profile/${id}/file-document`, {
		headers: await authHeader(),
		params: query,
	});
};

const updateFileDocument = async (id, payload) => {
	return axios.put(`${API_URL_DEFAULT}profile/${id}/file-document`, payload, {
		headers: await authHeader(),
	});
};

const checkProfile = async (query) => {
	return axios.get(`${API_URL_DEFAULT}profile/check-profile`, {
		headers: await authHeader(),
		params: query,
	});
};

export default {
	read,
	readByID,
	readByUserID,
	readBiodata,
	updateBiodata,
	readEducationFormal,
	readEducationInformal,
	updateEducationFormal,
	updateEducationInformal,
	readSkill,
	updateSkill,
	readLanguage,
	updateLanguage,
	readWorkExperience,
	updateWorkExperience,
	readFileDocument,
	updateFileDocument,
	checkProfile,
};
