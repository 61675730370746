import React from 'react';
import { Link } from 'react-router-dom';

import Background from '../../assets/img/lnk/landingbackground.png';
import LogoPortal from '../../assets/img/lnk/logoportal.png';
import Fibercreme from '../../assets/img/lnk/fibercreme.png';
import Richcreme from '../../assets/img/lnk/rich-creme.png';
import Lautandairy from '../../assets/img/lnk/lautan-dairy.png';
import Lautanpremix from '../../assets/img/lnk/lautan-premix.png';
import Lautankrimer from '../../assets/img/lnk/lautan-krimer.png';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Button from '../../components/bootstrap/Button';

const index = () => {
	return (
		<PageWrapper title='Job Portal'>
			<Page
				container='fluid'
				className='vh-100'
				style={{ background: `url(${Background})`, backgroundSize: 'cover', padding: 0 }}>
				<div className='row h-100'>
					<div className='col-8'>
						<br />
					</div>
					<div
						className='col-4 d-flex flex-column'
						style={{
							boxSizing: 'border-box',
							border: '1px solid #DEE2E6',
							boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
							borderRadius: '32px 0px 0px 32px',
						}}>
						<div className='text-center p-5'>
							<img src={LogoPortal} className='w-75' alt='LNK' />
							<p className='mt-3' style={{ fontSize: 34 }}>
								Job Portal
							</p>
							<Link className='mt-3' to='/job'>
								<Button icon='search' type='button' color='primary'>
									Search Job
								</Button>
							</Link>
						</div>
						<div className='d-flex align-items-end h-100'>
							<div className='col text-center py-4'>
								<img src={Fibercreme} alt='Fiber Creme' />
							</div>
							<div className='col text-center py-4'>
								<img src={Richcreme} alt='Rich Creme' />
							</div>
							<div className='col text-center py-4'>
								<img src={Lautandairy} alt='Lautan Dairy' />
							</div>
							<div className='col text-center py-4'>
								<img src={Lautanpremix} alt='Lautan Premix' />
							</div>
							<div className='col text-center py-4'>
								<img src={Lautankrimer} alt='Lautan Krimer ' />
							</div>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
export default index;
