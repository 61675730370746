import AuthService from '../services/auth.service';

const logout = () => {
	return AuthService.logout();
};
const generateToken = () => {
	return AuthService.generateToken();
};

const getMenu = (role) => {
	return AuthService.getMenu(role).then(
		(data) => {
			return Promise.resolve(data);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();

			return Promise.reject(message);
		},
	);
};

const login = (username, password) => {
	return AuthService.login(username, password).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const register = (payload) => {
	return AuthService.register(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const forgotPassword = (payload) => {
	return AuthService.forgotPassword(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const verifyOtp = (payload) => {
	return AuthService.verifyOtp(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

const checkVerify = (payload) => {
	return AuthService.checkVerify(payload).then(
		(response) => {
			return Promise.resolve(response.data, response.data.message);
		},
		(error) => {
			const message =
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
			return Promise.reject(message);
		},
	);
};

export default {
	login,
	register,
	getMenu,
	logout,
	generateToken,
	verifyOtp,
	forgotPassword,
	checkVerify,
};
