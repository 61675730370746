import axios from 'axios';
import authHeader from './bakti/auth-header.service';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const readByID = async (onedrive_id) => {
	return axios.get(`${API_URL_DEFAULT}file/${onedrive_id}`, {
		headers: await authHeader(),
	});
};

export default { readByID };
