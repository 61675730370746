import { React, useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { decode } from 'html-entities';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import JobPortalHeader from '../../pages/common/Headers/JobPortalHeader';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Select from '../../components/bootstrap/forms/Select';
import Card, { CardBody } from '../../components/bootstrap/Card';
import JobPublishModule from '../../modules/sodiq/JobPublishModule';
import JobAppliedModule from '../../modules/sodiq/JobAppliedModule';
import Button from '../../components/bootstrap/Button';
import Icon from '../../components/icon/Icon';
import Modal, { ModalBody } from '../../components/bootstrap/Modal';
import PageLayoutHeader from '../../pages/common/Headers/PageLayoutHeader';
import PaginationButtons, { PER_COUNT } from '../../components/PaginationButtons';
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import { getRequester } from '../../helpers/helpers';
import Badge from '../../components/bootstrap/Badge';
import RecruitmentProcess from './RecruitmentProcess';
import AuthModule from '../../modules/AuthModule';
import ProfileEmpModule from '../../modules/bakti/master/ProfileEmpModule';

const ModalLoading = (dt) => {
	const { loading, setLoading } = dt;
	return (
		<Modal isOpen={loading} size='sm' isCentered setIsOpen={setLoading} isStaticBackdrop>
			<ModalBody
				style={{ backgroundColor: '#6c5dd3', color: 'white' }}
				className='text-center'>
				<button className='btn btn-primary' type='button' disabled>
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span
						className='spinner-grow spinner-grow-sm'
						role='status'
						aria-hidden='true'
					/>
					&nbsp;
					<span className='sr-only'>Loading...</span>
				</button>
			</ModalBody>
		</Modal>
	);
};

const Main = () => {
	const [loading, setLoading] = useState(false);
	const [isOpenLogin, setIsOpenLogin] = useState(false);

	const [data, setData] = useState([]);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const [page, setPage] = useState(1);
	const [filter, setFilter] = useState(null);
	const [placement, setPlacement] = useState(null);
	const [type, setType] = useState(null);
	const [search, setSearch] = useState(null);
	const [isDetail, setIsDetail] = useState(null);
	const [detail, setDetail] = useState({});
	const [timeline, setTimeline] = useState([]);
	const [activeItemIndex, setActiveItemIndex] = useState(0);
	const [isVerif, setIsVerif] = useState(false);

	const inputPlacement = useRef(null);
	const inputType = useRef(null);
	const inputSearch = useRef(null);
	const [placements, setPlacements] = useState([]);
	const [types, setTypes] = useState([]);

	// const locationList = [
	// 	{ value: 'mojokerto', label: 'Mojosari (Mojokerto)' },
	// 	{ value: 'driyo', label: 'Driyorejo (Gresik)' },
	// 	{ value: 'semarang', label: 'Semarang' },
	// ];
	// const typeList = [
	// 	{ value: 'Fulltime', label: 'Full Time' },
	// 	{ value: 'Parttime', label: 'Part Time' },
	// 	{ value: 'Contract Based', label: 'Contract Based' },
	// ];

	const getAccToken = JSON.parse(localStorage.getItem('accessToken', {}));
	const { email, person_name } = getRequester();

	useEffect(() => {
		fetchData(page, perPage, filter);
		checkVerifEmail();
		fetchPlacementTypes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, perPage, filter, isDetail]);

	const fetchData = async (nPage, nPerPage = 10, nFilter = {}) => {
		setLoading(true);
		const params = {
			...nFilter,
			page: nPage,
			sizePerPage: nPerPage,
		};
		if (email) {
			params.email = email;
		}
		return JobPublishModule.read(new URLSearchParams(params))
			.then((res) => {
				setData(res.foundData);
				setTotalRows(res.countData);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const checkVerifEmail = () => {
		AuthModule.checkVerify(new URLSearchParams({ email }))
			.then(() => {
				setIsVerif(true);
			})
			.catch(() => {
				setIsVerif(false);
			});
	};

	// const fetchPlacementTypes = () => {
	// 	JobPublishModule.getPlacementsandTypes().then((res) => {
	// 		// console.log(res);
	// 		const dt = res.json();

	// 		// Check if data is null or undefined
	// 		if (!dt || !dt.types || !dt.placements) {
	// 			setPlacement([]);
	// 			setType([]);
	// 			return;
	// 		}

	// 		// Check if types or placements are null or empty
	// 		const formattedTypes = dt.types
	// 			? dt.types.map((typeItem) => ({
	// 					value: typeItem,
	// 					label: typeItem.replace(/([A-Z])/g, ' $1').trim(), // Convert camelCase to normal words
	// 			  }))
	// 			: [];

	// 		const formattedPlacements = dt.placements
	// 			? dt.placements.map((placementsItem) => ({
	// 					value: placementsItem,
	// 					label: placementsItem.replace(/([A-Z])/g, ' $1').trim(), // Convert camelCase to normal words
	// 			  }))
	// 			: [];

	// 		setPlacements(formattedPlacements);
	// 		setTypes(formattedTypes);
	// 		// setPlacement(res.placements);
	// 		// setType(res.types);
	// 	});
	// };

	const fetchPlacementTypes = () => {
		JobPublishModule.getPlacementsandTypes()
			.then((dt) => {
				console.log('Fetched data:', dt); // Log data yang diambil

				// Check if data is null or undefined
				if (!dt || !Array.isArray(dt.types) || !Array.isArray(dt.placements)) {
					console.warn('Data is null or undefined:', dt); // Log peringatan jika data null atau undefined
					setPlacements([]);
					setTypes([]);
					return;
				}

				// Check if types or placements are null or empty
				const formattedTypes = dt.types.map((typeItem) => ({
					value: typeItem,
					label: typeItem, // Convert camelCase to normal words
				}));
				console.log('Formatted types:', formattedTypes); // Log formatted types

				const formattedPlacements = dt.placements.map((placementsItem) => ({
					value: placementsItem,
					label: placementsItem, // Convert camelCase to normal words
				}));
				console.log('Formatted placements:', formattedPlacements); // Log formatted placements

				setPlacements(formattedPlacements);
				setTypes(formattedTypes);
			})
			.catch((err) => {
				console.error('Error fetching placement types:', err); // Log kesalahan
				setPlacements([]);
				setTypes([]);
			});
	};
	const handleSearch = () => {
		const init = {};
		if (placement) {
			init.placement = placement;
		}
		if (type) {
			init.type = type;
		}
		if (search) {
			init.search = search;
		}
		setPage(1);
		setPerPage(10);
		setFilter(init);
	};

	const handleReset = () => {
		setPlacement(null);
		setType(null);
		setSearch(null);
		inputPlacement.current.value = '';
		inputType.current.value = '';
		inputSearch.current.value = '';
		setFilter({});
	};

	const handleApply = () => {
		if (!getAccToken) {
			setIsOpenLogin(true);
		}
		if (getAccToken) {
			ProfileEmpModule.checkProfile({})
				.then((res) => {
					const payload = {
						job_publish: detail.trans_code,
						applicant_name: person_name,
						current_step: 'Applied',
						next_step: 'CV Screening',
						profile_candidate: res,
						soft_competency: {},
						technical_competency: {},
						timeline: {
							applied: {
								status: 'approved',
								request_at: new Date(),
								updated_by: {
									username: email,
									name: person_name,
									email,
								},
							},
							cv_screening: {
								status: 'waiting',
								request_at: new Date(),
							},
							hr_interview: {},
							psikotest: {},
							user_interview: {},
							offering: {},
							mcu: {},
							onboarding: {},
						},
						history_approval: [],
					};
					Swal.fire({
						title: 'Are you sures?',
						text: 'want to apply this job?',
						icon: 'info',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Yes',
						cancelButtonText: `No, Cancel`,
						reverseButtons: true,
					}).then((result) => {
						if (result.isConfirmed) {
							setLoading(true);
							JobAppliedModule.create(payload)
								.then(() => {
									Swal.fire({
										icon: 'success',
										title: 'Success',
										text: `Data has been saved Successfully`,
										timer: 2000,
									});
									setIsDetail(false);
								})
								.catch((err) => {
									Swal.fire('Warning', err, 'error');
								})
								.finally(() => {
									setLoading(false);
								});
						} else if (result.dismiss === Swal.DismissReason.cancel) {
							Swal.fire('Cancelled', 'Your data is safe :)', 'error');
						}
					});
				})
				.catch((err) => {
					Swal.fire('Warning', err, 'error');
				});
		}
	};

	const handleDetail = (job) => {
		const timelineArr = [];
		if (job?.timeline?.applied) {
			timelineArr.push({
				label: 'Applied',
				status: job?.timeline?.applied.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.applied.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.applied.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.cv_screening) {
			timelineArr.push({
				label: 'CV Screening',
				status: job?.timeline?.cv_screening.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.cv_screening.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.cv_screening.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.hr_interview) {
			timelineArr.push({
				label: 'HR Interview',
				status: job?.timeline?.hr_interview.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.hr_interview.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.hr_interview.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.psikotest) {
			timelineArr.push({
				label: 'Psikotest',
				status: job?.timeline?.psikotest.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.psikotest.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.psikotest.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.user_interview) {
			timelineArr.push({
				label: 'User Interview',
				status: job?.timeline?.user_interview.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.user_interview.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.user_interview.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.offering) {
			timelineArr.push({
				label: 'Offering',
				status: job?.timeline?.offering.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.offering.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.offering.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.mcu) {
			timelineArr.push({
				label: 'MCU',
				status: job?.timeline?.mcu.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.mcu.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.mcu.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		if (job?.timeline?.onboarding) {
			timelineArr.push({
				label: 'Onboarding',
				status: job?.timeline?.onboarding.status,
				color:
					// eslint-disable-next-line no-nested-ternary
					job?.timeline?.onboarding.status === 'approved'
						? 'success'
						: // eslint-disable-next-line no-nested-ternary
						job?.timeline?.onboarding.status === 'rejected'
						? 'danger'
						: 'warning',
			});
		}
		setActiveItemIndex(0);
		setTimeline([...timelineArr]);
		setDetail(job);
		setIsDetail(true);
	};

	return (
		<PageWrapper title='Job Portal'>
			<ModalLoading loading={loading} setLoading={setLoading} />
			{getAccToken ? (
				<>
					<PageLayoutHeader />
					{isDetail && (
						<header className='header header-back'>
							<div className='container-fluid'>
								<div className='row d-flex align-items-center'>
									<div className='row'>
										<div className='col-12'>
											<Button
												icon='ArrowBack'
												onClick={() => {
													setIsDetail(false);
												}}>
												Back
											</Button>
										</div>
									</div>
								</div>
							</div>
						</header>
					)}
				</>
			) : (
				<JobPortalHeader
					setLoading={setLoading}
					isOpenLogin={isOpenLogin}
					setIsOpenLogin={setIsOpenLogin}
				/>
			)}
			<Page container='fluid'>
				{getAccToken && !isVerif && (
					<div className='row'>
						<div className='col-12'>
							<Alert
								icon='Warning'
								isLight
								color='danger'
								rounded={2}
								borderWidth={0}
								className='shadow-3d-primary'>
								<AlertHeading tag='h2' className='h4'>
									Warning
								</AlertHeading>
								<span>Please verify your email!</span>
							</Alert>
						</div>
					</div>
				)}
				<div className='row'>
					{isDetail ? (
						<div className='col-12 d-flex mb-3 align-items-center justify-content-center'>
							<div className='flex-column rounded w-75 p-4 border'>
								{detail?.is_apply && (
									<RecruitmentProcess
										timeline={timeline}
										activeItemIndex={activeItemIndex}
										setActiveItemIndex={setActiveItemIndex}
									/>
								)}
								<Card className='w-100'>
									<CardBody>
										<div className='row'>
											<div className='col-12'>
												<h3>{parse(decode(detail?.job_title))}</h3>
											</div>
										</div>
										<div className='row'>
											<div className='col-12'>
												<Icon icon='HomeWork' color='dark' />{' '}
												{detail?.placement}
												<Icon
													icon='BusinessCenter'
													color='dark'
													style={{ marginLeft: '15px' }}
												/>{' '}
												{detail?.status_emp}
											</div>
										</div>
										<div className='row mt-3'>
											<div className='col-12'>
												<h5>Description</h5>
											</div>
										</div>
										<div className='row'>
											<div className='col-12'>
												<p>{parse(decode(detail?.recruit_message))}</p>
											</div>
										</div>
										<div className='row mt-2'>
											<div className='col-12'>
												<h5>Responsibilities</h5>
											</div>
										</div>
										<div className='row'>
											<div className='col-12'>
												<p>{parse(decode(detail?.job_desc))}</p>
											</div>
										</div>
										<div className='row mt-2'>
											<div className='col-12'>
												<h5>Requirement</h5>
											</div>
										</div>
										<div className='row'>
											<div className='col-12'>
												<p>
													<ul>
														{detail?.educational_level?.map((item) => (
															<li>{decode(item)}</li>
														))}
														<li>{decode(detail?.field_study)}</li>
														<li>{decode(detail?.skill)}</li>
														<li>
															{detail?.work_exp
																? `Work Experience ${detail?.work_exp?.min} - ${detail?.work_exp?.max} years`
																: ''}
														</li>
													</ul>
												</p>
											</div>
										</div>
										<div className='row mt-3'>
											<div className='col-12'>
												<Button
													color='info'
													isOutline
													className='float-start'
													icon='ArrowBack'
													onClick={() => {
														setIsDetail(false);
													}}>
													Back
												</Button>

												{!detail?.is_apply && (
													<Button
														onClick={() => handleApply()}
														color='info'
														className='float-end'>
														Apply
													</Button>
												)}
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					) : (
						<>
							<div className='col-12 d-flex mb-3 align-items-center justify-content-center'>
								<div className='flex-column rounded w-75 p-3 border'>
									<Card className='w-100'>
										<CardBody>
											<div className='row'>
												<div className='col offset-1 col-3'>
													<FormGroup
														id='location'
														label='Placement'
														isFloating>
														<Select
															list={placements}
															onChange={(e) => {
																setPlacement(e.target.value);
															}}
															value={placement}
															ref={inputPlacement}
														/>
													</FormGroup>
												</div>
												<div className='col-2'>
													<FormGroup
														id='job_type'
														label='Type'
														isFloating>
														<Select
															list={types}
															onChange={(e) => {
																setType(e.target.value);
															}}
															value={type}
															ref={inputType}
														/>
													</FormGroup>
												</div>
												<div className='col-3'>
													<FormGroup
														id='title'
														label='Search Job Title'
														isFloating>
														<Input
															placeholder='Search Job Title'
															onChange={(e) =>
																setSearch(e.target.value)
															}
															value={search}
															ref={inputSearch}
														/>
													</FormGroup>
												</div>
												<div className='col-2'>
													<Button
														color='primary'
														className='p-3'
														onClick={() => handleSearch()}>
														Filter
													</Button>
													<Button
														color='warning'
														className='p-3'
														style={{ marginLeft: '3px' }}
														onClick={() => handleReset()}>
														Reset
													</Button>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>
							<div className='col-12 d-flex mb-3 align-items-center justify-content-center'>
								<div className='flex-column rounded w-75 p-4 border'>
									{data.map((job) => {
										return (
											<Card className='w-100'>
												<CardBody>
													<div className='row'>
														<div className='col-9'>
															<h3>{parse(decode(job?.job_title))}</h3>
														</div>
														<div className='col-3'>
															{job?.is_apply && (
																<Badge
																	className='float-end'
																	rounded='pill'
																	color='success'>
																	Applied
																</Badge>
															)}
														</div>
													</div>
													<div className='row mt-3'>
														<div className='col-6'>
															<Icon icon='HomeWork' color='dark' />{' '}
															{job?.placement}
															<Icon
																icon='BusinessCenter'
																color='dark'
																style={{ marginLeft: '15px' }}
															/>{' '}
															{job?.status_emp}
														</div>
														<div className='col-6'>
															<Button
																onClick={() => {
																	handleDetail(job);
																}}
																color='info'
																isLight
																className='float-end'>
																View Details{' '}
																<Icon icon='KeyboardArrowRight' />
															</Button>
														</div>
													</div>
												</CardBody>
											</Card>
										);
									})}

									<PaginationButtons
										data={new Array(totalRows)}
										label='items'
										setCurrentPage={setPage}
										currentPage={page}
										perPage={perPage}
										setPerPage={setPerPage}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</Page>
		</PageWrapper>
	);
};
export default Main;
