import jwt_decode from 'jwt-decode';
import history from '../helpers/history';

const checkSessionExpired = async (token) => {
	let isExpired = false;
	const decodedToken = jwt_decode(token, process.env.REACT_APP_PRIVATE_KEY);

	const dateNow = new Date().getTime();
	const selisih = decodedToken.exp * 1000 - dateNow;
	if (decodedToken.exp * 1000 < dateNow) isExpired = true;
	return { isExpired, selisih };
};
export default async function refreshToken() {
	const token = JSON.parse(localStorage.getItem('accessToken'));
	const publicKey = process.env.REACT_APP_PUBLIC_KEY;

	const applicationToken = JSON.parse(localStorage.getItem('appToken'));
	if (token && token.accessToken) {
		const showExpired = checkSessionExpired(token.accessToken);
		if (showExpired.isExpired) {
			localStorage.setItem('message', 'Session is expired. Please login again');
			localStorage.removeItem('appToken');
			localStorage.removeItem('accessToken');
			history.push('/');
			window.location.reload();
		}
		return {
			'x-public-key': publicKey,
			'x-application-token': `Bearer ${applicationToken}`,
			'x-user-token': `Bearer ${token.accessToken}`,
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		};
	}
	return {
		'x-public-key': publicKey,
		'x-application-token': `Bearer ${applicationToken}`,
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	};
}
