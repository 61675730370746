import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import moment from 'moment';
import PropTypes from 'prop-types';
import useDarkMode from '../../../../hooks/useDarkMode';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Avatar from '../../../../components/Avatar';
// import User1Webp from '../../../../assets/img/wanna/wanna2.webp';
import User1Img from '../../../../assets/img/wanna/wanna2.png';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Checks from '../../../../components/bootstrap/forms/Checks';
import Button from '../../../../components/bootstrap/Button';
import LoadingModal from '../../../../components/custom/LoadingModal';
import DatePicker from '../components/DatePicker';
import Label from '../../../../components/bootstrap/forms/Label';
import FileModule from '../../../../modules/FileModule';
import Select from '../components/Select';

const MAX_FILE = 2 * 1024 * 1024;

const TableBiodata = ({
	initialValues,
	listGender,
	listNationalities,
	listReligions,
	loading,
	onSubmit,
}) => {
	const { darkModeStatus } = useDarkMode();

	const [imgProfile, setImgProfile] = useState(null);

	const formik = useFormik({
		initialValues: {
			...initialValues,
		},
		validate: (values) => {
			const errors = {};

			if (values?.photo_obj && values?.photo_obj?.size > MAX_FILE) {
				errors.photo = 'File size exceeds the maximum limit (2MB).';
			}

			return errors;
		},
		validationSchema: Yup.object({
			fullname: Yup.string().required('Required'),
			nickname: Yup.string().required('Required'),
			phone_number: Yup.string().required('Required'),
			personal_email: Yup.string().required('Required'),
			birth_date: Yup.string().required('Required'),
			birth_city: Yup.string().required('Required'),
			religion: Yup.object().nullable().required('Required'),
			gender: Yup.object().nullable().required('Required'),
			domicile: Yup.string().required('Required'),
			ktp_address: Yup.string().when('same_domicile', {
				is: false,
				then: Yup.string().required('Required'),
			}),
			nationality: Yup.object().nullable().required('Required'),
			contacts: Yup.array()
				.min(1)
				.of(
					Yup.object().shape({
						fullname: Yup.string().required('Required'),
						phone_number: Yup.string().required('Required'),
						relation: Yup.string().nullable(),
					}),
				)
				.required('Required'),
		}),
		onReset: () => {},
		onSubmit: (values, { setSubmitting, setErrors }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: 'Please check your entries !',
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes',
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						const new_data = {
							profiles: JSON.stringify({
								fullname: values.fullname,
								nickname: values.nickname,
								phone_number: values.phone_number,
								personal_email: values.personal_email,
								birth_city: values.birth_city,
								birth_date: values.birth_date,
								religion: values.religion?.value || '',
								gender: values.gender?.value || '',
								domicile: values.domicile,
								ktp_address: values.same_domicile
									? values.domicile
									: values.ktp_address,
								nationality: values.nationality?.value || '',
								same_domicile: values?.same_domicile,
								photo: values.photo,
							}),
							emergency_contacts: values.contacts,
						};
						const formData = new FormData();
						formData.append('data', JSON.stringify(new_data));
						formData.append('photo', values.photo_obj);

						onSubmit(formData);
					}
				});
			} catch (error) {
				setErrors({ submit: error.message });
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			} finally {
				setSubmitting(false);
			}
		},
	});

	useEffect(() => {
		const fetchPhoto = async (onedrive_id) => {
			return FileModule.readByID(onedrive_id)
				.then((res) => {
					const img = `data:${res?.mimetype};base64,${res?.file}`;
					setImgProfile(img);
				})
				.catch(() => {});
		};

		if (initialValues?.profile_photo) {
			fetchPhoto(initialValues?.profile_photo);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	const onAddContact = () => {
		const _data = formik.values.contacts;
		_data.push({ fullname: '', phone_number: '', relation: '' });
		formik.setFieldValue('contacts', _data);
	};

	const onRemoveContact = (index) => {
		const _data = formik.values.contacts;
		_data.splice(index, 1);
		formik.setFieldValue('contacts', _data);
	};

	return (
		<Card stretch tag='form' noValidate onSubmit={formik.handleSubmit}>
			<CardHeader
				size='sm'
				borderSize={1}
				style={{ backgroundColor: darkModeStatus ? '#4d69fa33' : '#edf0ff' }}>
				<CardLabel icon='Person' iconColor='info'>
					<CardTitle>Account Details</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody isScrollable>
				<div className='row g-4 align-items-center'>
					{/* PHOTO */}
					<div className='col-xl-auto'>
						<Avatar src={imgProfile || User1Img} />
					</div>
					<div className='col-xl'>
						<div className='row g-4'>
							<div className='col-5'>
								<FormGroup id='photo' formText='Max size 2MB with JPEG extension'>
									<Input
										data-testid='inputProfilePicture'
										type='file'
										onChange={(e) => {
											const file = e?.target?.files?.[0] || null;
											formik.setFieldValue('photo_obj', file);
											formik.handleChange(e);
										}}
										onBlur={formik.handleBlur}
										value={formik.values.photo}
										isValid={formik.isValid}
										isTouched={formik.touched.photo}
										invalidFeedback={formik.errors.photo}
										accept='.jpeg'
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<p className='lead text-muted'>
									Picture helps your teammates get to know you
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className='my-3'>
					<div className='col-12 border-bottom' />
				</div>

				{/* PROFILE */}
				<div className='row g-4'>
					<div className='col-xl-6 col-sm-12'>
						<FormGroup id='fullname' label='Full Name'>
							<Input
								data-testid='inputFullName'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.fullname}
								isValid={formik.isValid}
								isTouched={formik.touched.fullname}
								invalidFeedback={formik.errors.fullname}
							/>
						</FormGroup>
					</div>
					<div className='col-xl-6 col-sm-12'>
						<FormGroup id='nickname' label='Nick Name'>
							<Input
								data-testid='inputNickName'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.nickname}
								isValid={formik.isValid}
								isTouched={formik.touched.nickname}
								invalidFeedback={formik.errors.nickname}
							/>
						</FormGroup>
					</div>
					<div className='col-xl-6 col-sm-12'>
						<FormGroup id='phone_number' label='Phone Number'>
							<Input
								data-testid='inputPhoneNumber'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.phone_number}
								isValid={formik.isValid}
								isTouched={formik.touched.phone_number}
								invalidFeedback={formik.errors.phone_number}
							/>
						</FormGroup>
					</div>
					<div className='col-xl-6 col-sm-12'>
						<FormGroup id='personal_email' label='Personal Email'>
							<Input
								data-testid='inputPersonalEmail'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.personal_email}
								isValid={formik.isValid}
								isTouched={formik.touched.personal_email}
								invalidFeedback={formik.errors.personal_email}
								readOnly
							/>
						</FormGroup>
					</div>
					<div className='col-xl-3 col-sm-12'>
						<FormGroup id='birth_city' label='City Birth'>
							<Input
								data-testid='inputBirthCity'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.birth_city}
								isValid={formik.isValid}
								isTouched={formik.touched.birth_city}
								invalidFeedback={formik.errors.birth_city}
							/>
						</FormGroup>
					</div>
					<div className='col-xl-3 col-sm-12'>
						<Label>Date Birth</Label>
						<div>
							<DatePicker
								data-testid='datepickerDateBirth'
								name='birth_date'
								placeholder='DD/MM/YYYY'
								date_format='dd/MM/yyyy'
								value={formik.values?.birth_date}
								onChange={(value) => {
									if (!value) {
										formik.setFieldValue('birth_date', '');
									} else {
										const _value = moment(value).format('YYYY-MM-DD');
										formik.setFieldValue('birth_date', _value);
									}
								}}
								onBlur={formik.handleBlur}
								isValid={formik.isValid}
								isTouched={formik.touched.birth_date}
								invalidFeedback={formik.errors.birth_date}
								isRequired
							/>
						</div>
					</div>
					<div className='col-xl-3 col-sm-12'>
						<FormGroup id='religion' label='Religion'>
							<Select
								data-testid='selectReligion'
								options={listReligions}
								onChange={(e) => formik.setFieldValue('religion', e)}
								onBlur={formik.handleBlur}
								value={formik.values.religion}
								isValid={formik.isValid || !formik.errors.religion}
								isTouched={formik.touched.religion}
								invalidFeedback={formik.errors.religion}
							/>
						</FormGroup>
					</div>
					<div className='col-xl-3 col-sm-12'>
						<FormGroup id='gender' label='Gender'>
							<Select
								data-testid='selectGender'
								options={listGender}
								onChange={(e) => formik.setFieldValue('gender', e)}
								onBlur={formik.handleBlur}
								value={formik.values.gender}
								isValid={formik.isValid || !formik.errors.gender}
								isTouched={formik.touched.gender}
								invalidFeedback={formik.errors.gender}
							/>
						</FormGroup>
					</div>
					<div className='col-xl-6 col-sm-12'>
						<FormGroup id='domicile' label='Domicilie Address'>
							<Input
								data-testid='inputDomicile'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.domicile}
								isValid={formik.isValid}
								isTouched={formik.touched.domicile}
								invalidFeedback={formik.errors.domicile}
							/>
						</FormGroup>
					</div>
					<div className='col-xl-6 col-sm-12'>
						<FormGroup id='ktp_address' label='Address (same on KTP)'>
							<Input
								data-testid='inputKTPAddress'
								type='text'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.ktp_address}
								isValid={formik.isValid}
								isTouched={formik.touched.ktp_address}
								invalidFeedback={formik.errors.ktp_address}
								readOnly={formik.values.same_domicile}
							/>
						</FormGroup>
						<Checks
							type='checkbox'
							id='same_domicile'
							label='Same on domicile address'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							checked={formik.values.same_domicile}
							isTouched={formik.touched.same_domicile}
						/>
					</div>
					<div className='col-xl-12 col-sm-12'>
						<FormGroup id='nationality' label='Nationality'>
							<Select
								data-testid='selectNationality'
								options={listNationalities}
								onChange={(e) => formik.setFieldValue('nationality', e)}
								onBlur={formik.handleBlur}
								value={formik.values.nationality}
								isValid={formik.isValid || !formik.errors.nationality}
								isTouched={formik.touched.nationality}
								invalidFeedback={formik.errors.nationality}
							/>
						</FormGroup>
					</div>
				</div>

				<div className='my-3'>
					<div className='col-12 border-bottom' />
				</div>

				{/* EMERGENCY CONTACTS */}
				<div className='d-flex my-1'>
					<div className='flex-grow-1'>
						<b>Emergency Contact</b>
					</div>
					<div>
						<Button
							data-testid='buttonAddNew'
							icon='Add'
							color='light'
							type='button'
							size='sm'
							onClick={() => onAddContact()}
							isLight={darkModeStatus}>
							Add New
						</Button>
					</div>
				</div>

				{formik.values.contacts.map((contact, index, arr) => (
					<div className='row g-4 my-1' key={'contact'.concat(index)}>
						<div className='col-xl-11'>
							<div className='row g-4'>
								<div className='col-xl-4'>
									<FormGroup id='fullname' label='Full Name' isFloating>
										<Input
											data-testid={'inputContactFullName'.concat(index)}
											type='text'
											autoComplete='off'
											onChange={(e) => {
												const new_values = [...formik.values.contacts];
												new_values[index].fullname = e.target.value;
												formik.setFieldValue('contacts', new_values);
											}}
											onBlur={formik.handleBlur}
											value={formik.values.contacts?.[index]?.fullname || ''}
											isValid={formik.isValid}
											isTouched={!!formik.touched.contacts}
											invalidFeedback={
												formik.errors.contacts?.[index]?.fullname
											}
										/>
									</FormGroup>
								</div>
								<div className='col-xl-4'>
									<FormGroup id='phone_number' label='Phone Number' isFloating>
										<Input
											data-testid={'inputContactPhoneNumber'.concat(index)}
											type='text'
											autoComplete='off'
											onChange={(e) => {
												const new_values = [...formik.values.contacts];
												new_values[index].phone_number = e.target.value;
												formik.setFieldValue('contacts', new_values);
											}}
											onBlur={formik.handleBlur}
											value={
												formik.values.contacts?.[index]?.phone_number || ''
											}
											isValid={formik.isValid}
											isTouched={!!formik.touched.contacts}
											invalidFeedback={
												formik.errors.contacts?.[index]?.phone_number
											}
										/>
									</FormGroup>
								</div>
								<div className='col-xl-4'>
									<FormGroup id='relation' label='Relation' isFloating>
										<Input
											data-testid={'inputContactRelation'.concat(index)}
											type='text'
											autoComplete='off'
											onChange={(e) => {
												const new_values = [...formik.values.contacts];
												new_values[index].relation = e.target.value;
												formik.setFieldValue('contacts', new_values);
											}}
											onBlur={formik.handleBlur}
											value={formik.values.contacts?.[index]?.relation || ''}
											isValid={formik.isValid}
											isTouched={!!formik.touched.contacts}
											invalidFeedback={
												formik.errors.contacts?.[index]?.relation
											}
										/>
									</FormGroup>
								</div>
							</div>
						</div>
						<div className='col-xl-1'>
							<Button
								data-testid={'buttonDelete'.concat(index)}
								icon='Delete'
								color='dark'
								type='button'
								size='lg'
								isDisable={arr.length <= 1}
								onClick={() => onRemoveContact(index)}
								isLight={darkModeStatus}
								className='mx-1'
							/>
						</div>
					</div>
				))}
			</CardBody>
			<CardFooter>
				<CardFooterRight>
					<Button
						data-testid='buttonSubmit'
						type='submit'
						icon='Save'
						color='info'
						isDisable={!formik.isValid && !!formik.submitCount}
						isLight={darkModeStatus}>
						Save
					</Button>
				</CardFooterRight>
			</CardFooter>

			<LoadingModal loading={loading} />
		</Card>
	);
};

TableBiodata.propTypes = {
	initialValues: PropTypes.oneOfType([PropTypes.object]),
	listReligions: PropTypes.oneOfType([PropTypes.array]),
	listGender: PropTypes.oneOfType([PropTypes.array]),
	listNationalities: PropTypes.oneOfType([PropTypes.array]),
	loading: PropTypes.bool,
	onSubmit: PropTypes.func,
};
TableBiodata.defaultProps = {
	initialValues: null,
	listReligions: [],
	listGender: [],
	listNationalities: [],
	loading: false,
	onSubmit: () => {},
};

export default TableBiodata;
