import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import useDarkMode from '../../../../hooks/useDarkMode';
import Nav, { NavItem } from '../../../../components/bootstrap/Nav';
import TableFormal from './TableFormal';
import TableInformal from './TableInformal';

const FormEducation = ({ id, isLoading }) => {
	const { darkModeStatus } = useDarkMode();

	const TABS = {
		FORMAL: 'Formal',
		INFORMAL: 'Informal',
	};
	const [tab, setTab] = useState(TABS.FORMAL);

	return (
		<Card stretch>
			<CardHeader
				size='sm'
				borderSize={1}
				style={{ backgroundColor: darkModeStatus ? '#4d69fa33' : '#edf0ff' }}>
				<CardLabel icon='School' iconColor='info'>
					<CardTitle>Education</CardTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<Nav className='px-1' design='pills'>
					{Object.values(TABS).map((value, index) => (
						<NavItem
							data-testid={'nav'.concat(value)}
							key={'index'.concat(index)}
							onClick={() => setTab(value)}
							isActive={tab === value}>
							<div>{value}</div>
						</NavItem>
					))}
				</Nav>

				<div className='mb-2' />

				{tab === TABS.FORMAL && <TableFormal id={id} isLoading={isLoading} />}

				{tab === TABS.INFORMAL && <TableInformal id={id} isLoading={isLoading} />}
			</CardBody>
		</Card>
	);
};

FormEducation.propTypes = {
	id: PropTypes.string,
	isLoading: PropTypes.bool,
};
FormEducation.defaultProps = {
	id: null,
	isLoading: true,
};

export default FormEducation;
