import axios from 'axios';
import authHeader from '../auth-header.service';

const API_URL_DEFAULT = process.env.REACT_APP_API;

const readByID = async (id, query) => {
	return axios.get(`${API_URL_DEFAULT}onboarding/${id}`, {
		headers: await authHeader(),
		params: query,
	});
};

const updateByID = async (id, payload) => {
	return axios.put(`${API_URL_DEFAULT}onboarding/${id}`, payload, {
		headers: await authHeader(),
	});
};

const readApplied = async (id) => {
	return axios.get(`${API_URL_DEFAULT}onboarding/${id}/applied`, {
		headers: await authHeader(),
	});
};

export default { readByID, updateByID, readApplied };
